
(()=>{
    let images=["bgimg.png","BSLogo.png","desk-logo.png","error404.png","logo.png","ppay.jpg","soldout.png","unpaid.png","updater.png","emptyCart.png","menuBnt.png","notimg.png"
,"v1.png","v2.png","v3.png","v4.png","desk-printer.png","desk-printer-yes.png","scanOrder.png"
,"changyong.png","zhangdan.png","yewu.png","qita.png"];

    images.forEach(it=>{
        loadImage("/images/"+it);
    })
    function loadImage(src){
        const img= (new Image())
        img.src=src;
    }
})();
