
import httpAES from './http.js'
import auth from './auth.js'
import cacheData from './cacheData'
import mqTTClient from './mqTTClient'
import { webBrowser } from '../common'
import {global} from './global'
class EventBus {
	constructor() {
		this.events = {};
	}

	on(event, callback) {
		this.events[event] = this.events[event] || [];
		this.events[event].push(callback);
	}

	emit(event, payload) {
		(this.events[event] || []).forEach(callback => callback(payload));
	}

	off(event, callback){
		if(callback){
			let index=(this.events[event]||[]).findIndex(it=>it==callback);
			if(index>=0){
				this.events[event].splice(index,1);
			}
		}else{
			delete this.events[event];
		}
	}
}
//键盘是否可用
const keyavail=true;
export {
	httpAES,
	auth,
	cacheData,
	mqTTClient,
	global
}

export default {
	install(Vue){
		// 添加全局对象
		Vue.config.globalProperties.$httpAES = httpAES;
		Vue.config.globalProperties.$auth = auth;
		Vue.config.globalProperties.$cacheData=cacheData;
		Vue.config.globalProperties.$mqTTClient=mqTTClient;
		Vue.config.globalProperties.$global=global;
		Vue.config.globalProperties.$keyavail=keyavail;

		Vue.config.globalProperties.$keyBoard={
			show(){},
			close(){}
		}
		Vue.config.globalProperties.$EventBus=new EventBus();
		
		Vue.config.globalProperties.$checkUser={
			/**
			 * 验证用户
			 * @param title 提示内容
			 * @param param 参数
			 */
			show(title,param){}
		};

		//监听mq在线状态
		webBrowser.on("mqState",(data)=>{
			if(data){
				global.mqState=data.state;//0离线  1连接中 2在线,
				if(data.state==2 && data.offToOn){ //offToOn:true 从离线到在线状态切换  timestamps 模拟一个最新时间戳 用来更新推送数据
					//模拟推送 桌台数据 使桌台数据刷新
					window.jsRegister("mqCloudPosData",{DataType:"Desk_MapData",HisDataVersion:data.timestamps,NowDataVersion:data.timestamps});
					cacheData.RefreshSoldInfo().catch(e=>{
						console.log('mq离线转在线--->更新菜品售卖信息失败:',e);
					});
				}
			}
		});
	}
}