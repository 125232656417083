<template>
    <modal class="selectFootModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">套餐替换菜品
            <div class="title">
                <template v-if="data?.ReplaceType==1">换入菜品任意选择</template>
                <template v-else-if="data?.ReplaceType==2">换入菜品售价区间：{{minPrice}}元~{{maxPrice}}元</template>
            </div>
        </div>
        <div class="modal-body">
            <div class="category-nav food-main-box"  v-if="orderMap_Type==2 ||orderMap_Type==1" :style="foodMainStyle">
                <categorys-page 
                    :data="foodMainCategory" 
                    :row="foodMainArg.row" 
                    :col="foodMainArg.col" 
                    :selectIndex="mainCategoryIndex"
                    :bottomH="setBottomH"
                    :pageIndex="foodMainPageIndex"
                    navFirstName="全部大类"
                    v-slot="{item}" @navClick="onFoodNav">
                    {{item.MainCategory_Name}}
                </categorys-page>
            </div>
            <div class="category-nav food-sub-box"  v-if="orderMap_Type==3 ||orderMap_Type==1" :style="foodSubStyle">
                <categorys-page  
                    :data="foodSubCategorys" 
                    :row="foodSubArg.row" 
                    :col="foodSubArg.col" 
                    :selectIndex="subCategoryIndex"
                    :bottomH="setBottomH"
                    :pageIndex="foodSubPageIndex"
                    navFirstName="全部小类"
                    v-slot="{item}" @navClick="onFoodSubNav">
                    {{item.SubCategory_Name}}
                </categorys-page>
            </div>

            <div class="menu" :style="menuStyle">
                <!-- 菜单 -->
                <layout-rc class="item-box clearfix" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :isVarCss="true">
                    <div class="item-li " rc-item v-for="item in foodsPages" :key="item"  @click="itemClick(item)">
                        <div class="title">
                            {{item.Food_Name}}
                        </div>
                        <div class="bottom-box">
                            <span class="text-left" v-if="item.Is_Limited && item.Is_LimitedProject">剩: {{item.RemainNum}}</span>
                            <span class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</span>
                        </div>
                        <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                        <div class="tag tag-grey" v-else-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                        <!-- <div class="tag tag-limited" v-else-if="item.Is_Limited">限量</div> -->
                    </div>
                </layout-rc>
            </div>
            <div class="search-box">
                <key-board-input class="search"
                    ref="searchBox"
                    type="text" v-model="varietySelect" placeholder="菜品模糊检索"
                    isKeyEnterClose isBlurClose
                    :focusLock="true"
                    :keyOptions="{isKeyDownEnter:true}"
                    @keydown.enter="onEnter()"
                ></key-board-input>
                <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                <div class="float-right">
                    <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                    <span class="page-number">{{ pageIndex }}/{{ pageCount }}页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()" >取消</button>
        </div>
        <!--菜品换单位-->
        <modal-load :isShow="unitShow">
            <change-Unit-Model :isShow="unitShow" @closeModel="unitShow=false" :multipleSizeFoods="theMultipleSizeFoods" @onPlaceFoods="itemClick" ></change-Unit-Model>
        </modal-load>
    </modal>
</template>

<script>
import changeUnitModel from '../../changeUnitModel/changeUnitModel.vue';
//套餐替换菜品
export default {
    name:"selectFootModel",
    components:{changeUnitModel},
    emits:["colse","change"],
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
        /**
         * {
         *     ReplaceType:0,//替换菜品控制类型 0或空:不允许替换 1:任意替换 2:控制替换
         *     ReplaceMaxPercent:0,//控制替换的单价上浮百分比
         *     ReplaceMinPercent:0,//控制替换的单价下浮百分比
         *     MealDetails:null,//子菜品
         * }
         */
        data:{
            type:Object,
            default:null
        },
        /**要过滤的菜品id */
        notFoodIds:{
            type:Array,
            default:null
        }
    },
    computed:{
        //系统参数配置
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
        /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
        orderMap_Type(){
            return this.sysSetting.OrderMap_Type||1;
        },
        //所以菜品数据
        getPosFoodData(){
            return this.posFoodData?.data||[];
        },
        /**单价上浮百分比 */
        maxPrice(){
            let price=0;
            if(this.data?.ReplaceType==2 && this.data?.MealDetails){
                price= Number(this.data.MealDetails.Food_SetPrice).compute(1+this.data.ReplaceMaxPercent/100,'*');
            }
            return price;
        },
        /**单价下浮百分比 */
        minPrice(){
            let price=0;
            if(this.data?.ReplaceType==2 && this.data?.MealDetails){
                price= Number(this.data.MealDetails.Food_SetPrice).compute(1-this.data.ReplaceMinPercent/100,'*');
            }
            return price;
        },
        //大类
        foodMainCategory(){
            let main=[];
            this.getPosFoodData?.forEach(it=>{
                let categorys=[];
                it.FoodSubCategorys?.forEach(sub=>{
                    let PosFoods=sub.PosFoods;
                    if(this.data.ReplaceType==1){
                        PosFoods=sub.PosFoods?.filter(food=>!food.Is_SetMeal && !(this.notFoodIds?.indexOf(food.Food_ID)>=0));//必须是单菜
                    }else if(this.data.ReplaceType==2){
                        PosFoods=sub.PosFoods?.filter(food=>!food.Is_SetMeal && food.Food_Price>=this.minPrice && food.Food_Price<=this.maxPrice && !(this.notFoodIds?.indexOf(food.Food_ID)>=0));//必须是单菜 且在 范围内
                    }
                    if(PosFoods.length>0){
                        if(this.data?.MealDetails?.replaceItem){//如果之前替换过 需要排除掉之前的数据
                            PosFoods=PosFoods.filter(it=>(!it.Is_MultipleSize && it.Food_ID!=this.data.MealDetails.replaceItem.Food_ID) || it.Is_MultipleSize);
                        }else if(this.data?.MealDetails){//排除掉 当前菜的数据
                            PosFoods=PosFoods.filter(it=>it.Food_ID!=this.data.MealDetails.Food_ID);
                        }
                        categorys.push(Object.assign({},sub,{PosFoods:PosFoods}))
                    }
                })
                if(categorys.length>0){
                    main.push(Object.assign({},it,{FoodSubCategorys:categorys}));
                }
            })
            return main;
        },
        //全部小类
        allSubCategorys(){
            let all=[];
            this.foodMainCategory?.forEach(it=>{
                all=all.concat(it.FoodSubCategorys);
            });
            return all;
        },
        //小类
        foodSubCategorys(){
            let foodSubCategorys=this.allSubCategorys;
            if(this.mainCategoryIndex>0){//大类分类 第几
                foodSubCategorys=this.foodMainCategory[this.mainCategoryIndex-1].FoodSubCategorys;
            }
            return foodSubCategorys;
        },
        /**全部菜品 过滤推荐菜分类*/
        allFoods(){
            let list=[];
            this.allSubCategorys?.forEach(it=>{
                list=list.concat(it.PosFoods);
            })
            return list;
        },
        /**菜品分页显示 */
        foodsPages(){
            return this.posFoodsFilter?.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        //一页多少条
        pageSize(){
            let size=this.arg.row*this.arg.col;
            return size;
        },
        //总条数
        pageCount(){
            return Math.ceil(this.posFoodsFilter.length/this.pageSize);
        },
        //分类 下菜品
        posFoods(){
            let list=[];
            if(this.subCategoryIndex>0){//分类 下菜品
                list=this.foodSubCategorys[this.subCategoryIndex-1].PosFoods;
            }else{//全部分类 下菜品  
                this.foodSubCategorys?.forEach(it=>{
                    list=list.concat(it.PosFoods);
                })
            }
            return list;
        },
        //筛选 当前 菜品数据
        posFoodsFilter(){
            let list=this.posFoods;
            let menuTxt=this.varietySelect?.toLowerCase();
            if(menuTxt){
                list=list.filter(it=>(it.Food_Name||"").toLowerCase().indexOf(menuTxt)>=0 ||
                        (it.Food_PinYin||"").toLowerCase().indexOf(menuTxt)>=0 || 
                        (it.Food_Code||"").toLowerCase().indexOf(menuTxt)>=0
                );
            }
            return list;
        },
    },
    watch:{
        "sysSetting.DisplayingConfigs":{//桌台和点单界面显示设置
            handler(newVal){
                newVal?.forEach((it)=>{
                    let style={};
                    if(it.ScreenPx>0){
                        style["height"]=it.ScreenPx+"%";
                    }
                    if(it.Button_Color){
                        style["--bgColor"]=it.Button_Color;
                    }
                    if(it.Font_Name){
                        style["--fontFamily"]=it.Font_Name;
                    }
                    if(it.Font_Size){
                        style["--fontSize"]=it.Font_Size+"px"
                    }
                    if(it.Font_Color){
                        style["--fontColor"]=it.Font_Color
                    }
                    let arg={row:it.IntRow,col:it.IntCol};
                    if(it.Config_Kind==11){//菜品大类显示类型
                        this.foodMainArg=arg;
                        this.foodMainStyle=style;
                    }else if(it.Config_Kind==12){//为菜品小类显示类型   
                        this.foodSubArg=arg;
                        this.foodSubStyle=style;
                    }else if(it.Config_Kind==13){//为菜品显示类型   
                        this.arg={row:it.IntRow,col:it.IntCol,marginW:'6',marginH:'6'};
                        this.menuStyle=style;
                    }else if(it.Config_Kind==14){//为功能键显示类型   
                        this.footerArg=arg;
                        this.footerStyle=style;
                    }
                })
            },
            immediate:true
        },
    },
    data(){
        let arg={row:6,col:6,marginW:'6',marginH:'6'}
        let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            arg={row:globalSysSetting.OrderMap_X,col:globalSysSetting.OrderMap_Y,marginW:'6',marginH:'6'};
        }
        return {
            menuStyle:{},
            //大类 样式
            foodMainStyle:{},
            foodMainArg:{row:2,col:8},
            //大类分页 当前页码 
            foodMainPageIndex:1,
            mainCategoryIndex:0,
            //小类 样式
            foodSubStyle:{},
            foodSubArg:{row:2,col:8},
            //小类分页 当前页码 
            foodSubPageIndex:1,
            subCategoryIndex:0,
            arg:arg,
            /**菜品检索 */
            varietySelect:'',
            //菜品数据 
            posFoodData:null,
            /**菜品页码 */
            pageIndex:1,
            tableList:[],
            /**多单位弹层 */
            unitShow:false,
            /**多单位数据 */
            theMultipleSizeFoods:null
        }
    },
    mounted(){
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        setBottomH(){
            let top=this.$refs.searchBox?.getBoundingClientRect().top;
            if(top>0){
                return window.innerHeight-top;
            }
            return 0;
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                this.mainCategoryIndex=index;
            }else{//所有
                this.mainCategoryIndex=0;
            }
            this.foodSubPageIndex=0;
            this.$nextTick(()=>{
                this.foodSubPageIndex=1;
            })
            this.onFoodSubNav(null,0)
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            this.subCategoryIndex=index;
            this.varietySelect="";
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. pageIndex<this.pageCount){
                    this.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.pageIndex<=1?1:this.pageIndex--;
            }
        },
        itemClick(item){
            console.log(item);
            if(item.MultipleSizeFoods?.length>0){
                this.theMultipleSizeFoods=item;
                this.unitShow=true;
            }else{
                this.$emit("change",item)
            }
        },
    }
}
</script>

<style lang="scss">
@import './selectFootModel.scss'
</style>