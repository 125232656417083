<template>
    <div class="flickerAni" :class="className">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name:"flickerAni",
    props:{
        /**类名  */
        ainClass:{
            type:String,
            default:null
        }
    },
    data(){
        return {
            /**类名 */
            className:""
        }
    },
    watch:{
        /**类名变化了 */
        ainClass:{
            handler(newVal,oldVal){
                if(newVal && oldVal && newVal!=oldVal){//类名变更
                    this.classAni(newVal,oldVal,6);
                    
                }else{
                    this.className=newVal;
                }
            },
            immediate:true,
        }
    },
    methods:{
        /**新旧类名 来回 切换 动画 */
        classAni(newVal,oldVal,count){
            if(count>0){
                this.className=newVal;
                if(this._time){
                    clearTimeout(this._time);
                }
                this._time=setTimeout(()=>{
                    this.className=oldVal;
                    if(this._time){
                        clearTimeout(this._time);
                    }
                    this._time=setTimeout(()=>{
                        this.classAni(newVal,oldVal,count-1);
                    },500)
                },500)
            }else{
                this.className=newVal;
            }
        }
    },
    unmounted(){
        if(this._time){
            clearTimeout(this._time);
        }
    }
}
</script>

<style>

</style>