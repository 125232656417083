<template>
    <modal class="callCheckNumModel" :isShow="isShow" isMask @click="hide()" @keyup.esc="hide">
        <div class="header">订单详情
            <i class="close-bnt iconfont icon-a-bg-close3x" @click="hide()"></i>
        </div>
        <div class="modal-body">
            <div class="filter-box">
                <div class="inline-block search-box">
                    <el-select v-model="searchType" placeholder="按取餐号" :readonly="navType!=0">
                        <el-option :value="0" label="按取餐号"></el-option>
                        <el-option :value="1" label="按订单金额"></el-option>
                        <el-option :value="2" label="按台号"></el-option>
                        <el-option :value="3" label="按结账单号"></el-option>
                    </el-select>
                    <key-board-input class="search"
                        type="text" v-model="searchText" placeholder="模糊检索"
                        isKeyEnterClose isBlurClose 
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
                <div class="nav-box" >
                    <div class="nav-li" :class="{selected:navType==0}" @click="navType=0">全部</div>
                    <div class="nav-li" :class="{selected:navType==1}" @click="navType=1">待核销<span class="tip-num">{{tipTotalNum.noWriteOff}}</span></div>
                    <div class="nav-li" :class="{selected:navType==2}" @click="navType=2">已核销<span class="tip-num">{{tipTotalNum.writeOff}}</span></div>
                </div>
            </div>
            <div class="data-box">
                <div class="left-box">
                    <div class="table-box">
                        <div class="table-thead">
                            <div class="tr">
                                <div class="column" :class="sortable?.prop=='Eat_ID'?sortable.order:''" @click="sortChange('Eat_ID')">取餐号
                                    <span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                </div>
                                <div class="column" :class="sortable?.prop=='Eat_SaleMoney'?sortable.order:''" @click="sortChange('Eat_SaleMoney')">订单金额
                                    <span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                </div>
                                <div class="column" :class="sortable?.prop=='Eat_ETime'?sortable.order:''" @click="sortChange('Eat_ETime')">结账时间
                                    <span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                </div>
                                <div class="column">操作</div>
                            </div>
                        </div>
                        <div class="table-tbody">
                            <div class="tr" :class="{selected:item.Eat_AutoID==currentData?.Eat_AutoID}" v-for="item in pageList" :key="item" @click="currentClick(item)">
                                <div class="flex-1">
                                    <div class="column-box">
                                        <div class="column">{{item.Eat_ID}}</div>
                                        <div class="column money" style="text-align: center;">￥{{item.Eat_SaleMoney}}</div>
                                        <div class="column" style="text-align: center;">{{dateFormat(item.Eat_ETime)}}</div>
                                    </div>
                                    <div class="content-box">
                                        <template v-if="item.Eat_DeskName">台号：{{item.Eat_DeskName}} &nbsp;</template>
                                        单号：{{item.Eat_CheckIndex}}
                                    </div>
                                </div>
                                <div class="column edit">
                                    <div class="bnt bnt-call" @click.stop="callTakeNo(item)"><i class="iconfont icon-a-tongzhi3x"/>叫号</div>
                                    <div class="bnt " @click.stop="cancelKitCheckOut(item)" v-if="item.CheckType"><i class="iconfont icon-gouxuanxuanzhong"/>已核销</div>
                                    <div class="bnt bnt-writeOff" @click.stop="kitCheckOut(item)" v-else><i class="iconfont icon-hexiao"/>核销</div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-box">
                        <span>当前第 {{tablePageIndex}} 页 &nbsp;&nbsp; 共{{tablePageSum}}页</span>
                        <div class="page-button">
                            <page-Turning class="but but-up" type="up" v-model="tablePageIndex" :pageCount="tablePageSum">上一页</page-Turning>
                            <page-Turning class="but but-down" type="down" v-model="tablePageIndex" :pageCount="tablePageSum">下一页</page-Turning>
                        </div>
                    </div>
                </div>
                <div class="details-box">
                    <div class="flex-1">
                        <template v-if="currentData">
                            <div class="panel-box" style="min-height:220px">
                                <div class="title">订单详情</div>
                                <div class="content-box">
                                    <div class="li-flex">
                                        <div class="td">
                                            <div class="lable-text">结账单号：</div>
                                            <div class="input-from">{{detailsInfo?.Eat_CheckIndex}}</div>
                                        </div>
                                        <div class="td">
                                            <div class="lable-text">操作站点:</div>
                                            <div class="input-from">{{detailsInfo?.Eat_StationName}}</div>
                                        </div>
                                    </div>
                                    <div class="li-flex">
                                        <div class="td">
                                            <div class="lable-text">结账人：</div>
                                            <div class="input-from">{{detailsInfo?.Eat_Czy}}</div>
                                        </div>
                                        <div class="td">
                                            <div class="lable-text">订单渠道：</div>
                                            <div class="input-from">{{detailsInfo?.Channel_Name}}</div>
                                        </div>
                                    </div>
                                    <div class="li-flex">
                                        <div class="td">
                                            <div class="lable-text">取餐号：</div>
                                            <div class="input-from">{{detailsInfo?.Eat_ID}}</div>
                                        </div>
                                        <div class="td">
                                            <div class="lable-text">订单金额：</div>
                                            <div class="input-from money">￥{{detailsInfo?.Eat_SaleMoney}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-box">
                                    <el-table class="el-table--scrollable-y" :data="detailsInfo?.ConsumeDetails" max-height="200px" style="width: 100%;">
                                        <el-table-column prop="Eat_XfCode" label="菜品编号"></el-table-column>
                                        <el-table-column prop="Eat_XfName" label="菜品名称"></el-table-column>
                                        <el-table-column prop="Eat_Number" label="数量">
                                            <template #default="scope">
                                                {{scope.row.Eat_Number}}
                                                <template v-if="scope.row.Eat_XfSize">/{{scope.row.Eat_XfSize}}</template>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="Eat_SaleMoney" label="金额"></el-table-column>
                                    </el-table>
                                </div>
                                <div class="total">合计：<span class="money">￥{{detailsTotal?.saleMoney}}</span></div>
                            </div>
                            <div class="panel-box">
                                <div class="title">付款明细</div>
                                <div class="payment-box">
                                    <div class="li" v-for="item in detailsInfo?.PayDetails" :key="item">
                                        <div class="name">{{item.Pay_Name}}</div>
                                        <div class="money">￥{{item.Eat_PaymentMoney}}</div>
                                    </div>
                                </div>
                                <div class="total">合计：<span class="money">￥{{detailsTotal?.payMoney}}</span></div>
                            </div>
                        </template>
                    </div>
                    <div class="footer-box">
                        <button class="btn btn-cancel" @click="hide()">返回</button>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
// 来电记录
export default {
    name:'callCheckNumModel',
    emits:["close"],
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            //检索类型
            searchType:0,
            //检索内容
            searchText:"",
            //0全部 1待核销 2已核销
            navType:1,
            //排序
            sortable:null,
            //订单数据
            tableList:[],
            //选中的数据
            currentData:null,
            //明细数据
            detailsInfo:null,
            tablePageIndex:1,
            tablePageSize:12,
            VoiceBroadcast_Speed:0,
            //取餐叫号播放声源取本地文件
            isCallLocalSound:false
        }
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        tableFilter(){
            this.tablePageIndex=1;
        }
    },
    computed:{
        //检索
        tableSearch(){
            let list=this.tableList||[];
            if(this.searchText?.trim()){
                let seach=this.searchText.trim().toUpperCase();
                if(this.searchType==0 ){//按取餐号
                    list=list.filter((item)=> (item.Eat_ID||'').toUpperCase().indexOf(seach)>=0);
                }else if(this.searchType==1 ){//按订单金额
                    list=list.filter((item)=>  item.Eat_SaleMoney==seach);
                }else if(this.searchType==2 ){//按台号
                    list=list.filter((item)=> (item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0);
                }else if(this.searchType==3 ){//按结账单号
                    list=list.filter((item)=>  (item.Eat_CheckIndex||'').toUpperCase().indexOf(seach)>=0);
                }
            }
            return list;
        },
        /**筛选数据 */
        tableFilter(){
            let list=Object.assign([],this.tableSearch);
            if(this.navType==1){
                list=list.filter((item)=> !item.CheckType);
            }else if(this.navType==2){
                list=list.filter((item)=> item.CheckType);
            }

            //排序
            if(this.sortable){
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            }else if(this.navType==1){
                //结账时间 顺序
                list.order("Eat_ETime",'asc');
            }else if(this.navType==2){
                //核销时间 倒序
                list.order("Eat_Operatortime",'desc');
            }
            return list;
        },
        tipTotalNum(){
            let total={
                writeOff:0,
                noWriteOff:0,
            };
            this.tableSearch?.forEach(it=>{
                if(it.CheckType){
                    total.writeOff++;
                }else{
                    total.noWriteOff++;
                }
            })
            return total;
        },
        /**分页数据 */
        pageList(){
            if(this.tableFilter && this.tableFilter.length>0){
                return this.tableFilter.slice((this.tablePageIndex-1)*this.tablePageSize,this.tablePageIndex*this.tablePageSize);
            }
            return [];
        },
        //表格数据 分页总页数 
        tablePageSum(){
            let lenth=1;
            if(this.tableFilter && this.tableFilter.length>0){
                lenth=Math.ceil(this.tableFilter.length/this.tablePageSize)||1;
            }
            return lenth;
        },
        //合计数据
        detailsTotal(){
            let total={saleMoney:0,payMoney:0};
            this.detailsInfo?.ConsumeDetails?.forEach((it)=>{
                total.saleMoney+=it.Eat_SaleMoney;
            })
            this.detailsInfo?.PayDetails?.forEach((it)=>{
                total.payMoney+=it.Eat_PaymentMoney;
            })
            total.saleMoney=Number(total.saleMoney.toFixed(2));
            total.payMoney=Number(total.payMoney.toFixed(2));
            return total;
        }
    },
    mounted(){
        this.$webBrowser.getBaseSetup().then(d=>{
            if(d){
                this.isCallLocalSound=d.isCallLocalSound===true;
            }
        })
        this.$nextTick(()=>{
            this.init();
        });
    },
    methods:{
        /**初始化数据 */
        init(){
            this.searchType=0;
            this.searchText="";
            this.navType=1;
            this.currentData=null;
            this.detailsInfo=null;
            this.loadList();
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
        },
        /*时间格式 */
        dateFormat(cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('hh:mm');
        },
        //点击排序
        sortChange(prop){
            console.log(prop)
            if(this.sortable?.prop==prop){
                if(this.sortable.order=="ascending"){
                    this.sortable.order='descending';
                }else if(this.sortable.order=="descending"){
                    this.sortable=null;
                }
            }else{
                this.sortable={
                    prop:prop,
                    order:"ascending"
                }
            }
            
        },
        /**加载订单数据 */
        loadList(){
            this.tablePageIndex=1;
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.KDS.GetFastFoodCallNumberOrders",{}).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody||[];
                }else{
                    this.tableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                loading.close();
                this.$message.error("加载订单数据失败："+e.message);
                console.log('加载订单数据失败：',e);
            })
        },
        currentClick(item){
            this.currentData=item;
            const loading = this.$loading({
                text: "订单详情加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.KDS.GetFastFoodCallNumberOrderDetail",{
                Eat_AutoID:item.Eat_AutoID
            }).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    this.detailsInfo=d.ResponseBody||[];
                }else{
                    this.detailsInfo=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.detailsInfo=[];
                loading.close();
                this.$message.error("加载订单详情数据失败："+e.message);
                console.log('加载订单详情数据失败：',e);
            })
        },
        callTakeNo(item){//KDS叫号
            const loading = this.$loading({
                text: "叫号请求中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let info=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.FastFoodCallNumberOrderDoCall",{
                Eat_AutoID:item.Eat_AutoID,
                Operate_User:info.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("叫号失败"+d.ResponseHeader.ResultDesc);
                    return;
                }
                if(d.ResponseBody){
                    d.ResponseBody.EAT_IDs=[item.Eat_ID];
                    this.callNumBroad(d.ResponseBody);
                }
                this.$message.success("叫号成功");
                
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "叫号失败", {confirmButtonText: "确定"});
            })
        },
        callNumBroad(data){
            if(data.Is_Voice){
                if(!(this.$cacheData.globalVariable?.GlobalSysSetting?.CloudPosVoiceBroadcastSpeed>0) &&
                     data.VoiceBroadcast_Speed>0 && this.VoiceBroadcast_Speed!=(data.VoiceBroadcast_Speed||4)*10){
                    this.VoiceBroadcast_Speed=(data.VoiceBroadcast_Speed||4)*10;
                    this.$webBrowser.ttsConfig(this.VoiceBroadcast_Speed);
                }
                if(this.$webBrowser.isMobile?.Windows && this.isCallLocalSound){//取餐叫号播放声源取本地文件
					data.EAT_IDs?.forEach(d=>{
						for(let i=0;i<data.VoiceBroadcast_Times;i++){
                            this.$cacheData.addBroadcast("#localSound#"+d);
                        }
					})
				}else{
					data.Voice_Contents?.forEach(d=>{
                        for(let i=0;i<data.VoiceBroadcast_Times;i++){
                            this.$cacheData.addBroadcast(d);
                        }
                    })
				}
                
            }
        },
        /**核销数据 */
        kitCheckOut(item){
            const loading = this.$loading({
                text: "核销数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let info=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.FastFoodCallNumberOrderVerify",{
                Eat_AutoID:item.Eat_AutoID,
                Operate_User:info.Login_Name,
            }).then((d)=>{
                console.log(d)
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("核销失败"+d.ResponseHeader.ResultDesc);
                    return;
                }
                item.Eat_Operatortime=(new Date()).Format("yyyy-MM-ddThh:mm:ss");
                item.CheckType=true;
                this.callNumBroad(d.ResponseBody);
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message,"核销失败", {confirmButtonText: "确定"});
            })
        },
        /**撤销数据 */
		cancelKitCheckOut(item){
            this.$confirm("您确认需要将【取餐号"+item.Eat_ID+"】取消核销吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm'){
						this.cancelVerify(item);
					}
				},
			});
		},
        cancelVerify(item){
            const loading = this.$loading({
                text: "取消核销数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let info=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.FastFoodCallNumberOrderCancelVerify",{
                Eat_AutoID:item.Eat_AutoID,
                Operate_User:info.Login_Name,
            }).then((d)=>{
                console.log(d)
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("取消失败"+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("取消成功");
                item.CheckType=false;
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "取消失败", {confirmButtonText: "确定"});
            })
        }
    }
}
</script>

<style lang="scss">
@import './callCheckNumModel.scss'
</style>