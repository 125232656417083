<template>
  <modal class="subItemModel" :class="{row5:rows>6}" :isShow="isShow" :isMask="true" @click="hide()" @keyup.esc="hide">
        <div class="header">更多功能
            <span class="header-x" @click="hide()" >×</span>
        </div>
        <div class="modal-body">
            <!-- <div class="item-box" >
                <div class="item-li" :class="{selected:item.ProjectSubItem_Key==ProjectSubItemKey}" 
                    v-for="(item,index) in otherFuncList" :key="index" 
                    @click="onDescClick(item,index)" >
                    <p class="title">{{item.ProjectSubItem_Name}}</p>
                    <span class="shortKey" v-if="item.ProjectSubItem_ShortcutKey">{{item.ProjectSubItem_ShortcutKey}}</span>
                </div>
            </div> -->
            <div class="item-box" v-for="group in groupOtherLisst" :key="group">
                <div class="group-title" v-show="group.GroupName">
                    <div class="icon"><img v-if="group.GroupIconUrl" :src="group.GroupIconUrl"/></div>
                    {{group.GroupName}}
                </div>
                <div class="item-li verticalCenter" :class="{selected:item.ProjectSubItem_Key==ProjectSubItemKey}" 
                    v-for="(item,index) in group.ProjectSubItem" :key="index" 
                    @click="onDescClick(item,index)" >
                    <div class="title inlineNowrap2">{{item.ProjectSubItem_Name}}</div>
                    <span class="shortKey" v-if="item.ProjectSubItem_ShortcutKey">{{item.ProjectSubItem_ShortcutKey}}</span>
                </div>
            </div>
        </div>
  </modal>
</template>

<script>

export default {
    name:'subItemModel',
    data(){
        return {
            ProjectSubItemKey:-1,
            /**即配套餐 */
            readyShow:false,
            /**整桌退菜 */
            wholeShow:false,
            groupKeyIcon:{
                "OftenFunction":"/images/changyong.png",//常用操作功能
                "OrderFunction":"/images/zhangdan.png",//账单操作功能
                "BusinessFunction":"/images/yewu.png",//业务操作功能
                "ElseFunction":"/images/qita.png"//其他功能
            }
        }
    },
    props:{
        isShow:Boolean,
        otherFuncList:Object,
    },
    computed:{
        /**当前 行数 */
        rows(){
            let num=0;
            this.groupOtherLisst?.forEach(it=>{
                if(it.GroupName){
                    num++;
                }
                if(it.ProjectSubItem.length>0){
                    num+=Math.ceil(it.ProjectSubItem.length/4);
                }
            });
            return num;
        },
        /**分组 更多 按钮 */
        groupOtherLisst(){
            let data=[];
            let group={};
            this.otherFuncList?.forEach(it=>{
                if(!group[it.ProjectSubItem_GroupKey]){
                    group[it.ProjectSubItem_GroupKey]=[];
                    data.push({
                        GroupIconUrl:this.groupKeyIcon[it.ProjectSubItem_GroupKey],
                        GroupKey:it.ProjectSubItem_GroupKey,
                        GroupIndex:it.ProjectSubItem_GroupIndex,
                        GroupName:it.ProjectSubItem_GroupName,
                        ProjectSubItem:group[it.ProjectSubItem_GroupKey]
                    })
                }
                group[it.ProjectSubItem_GroupKey].push(it);
            })
            data.order("GroupIndex","ASC").forEach(it=>{
                it.ProjectSubItem.order("ProjectSubItem_Index","ASC");
            })
            return data;
        }
    },
    watch:{
        /**打开换单位页面 */
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        }
    },
    mounted(){
        this.$nextTick(()=> {
           this.openimp()
        })
    },
    methods:{
        /**打开执行 */
        openimp(){
            this.ProjectSubItemKey=-1
        },
        hide(){
            this.$emit("closeModel")
        },
        /**按钮点击 */
        onDescClick(item,index){
            this.ProjectSubItemKey = item.ProjectSubItem_Key
            this.$emit("subItemReturn",item,index)
        },
        /**去除重复 */
        unique(newarr) {
            const res = new Map();
            return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
        }
    }
}
</script>

<style lang="scss">
@import "./subItemModel.scss";
</style>