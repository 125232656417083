<template>
	<div class="home" @click="isMore=false">
		<div class="header">
			<div class="log" @click="ImgClick()"><img width="40" height="32" src="/images/desk-logo.png" /></div>
			<div class="left-txt">
				<div class="name" @click="isSelectShow=true" v-if="IsAllowOperateMoreDepartments"><span class="text">{{Department_Name}}</span><i class="iconfont icon-pencil-create"></i></div>
				<div class="name" v-else><span class="text">{{Department_Name}}</span></div>
				<div style="margin: 0 10px;">营业日期：{{Rpt_Date}}</div>
				<div v-if="selectIndex==0 && options?.length>1">
					<el-select v-model="fastFood" placeholder="中餐" class="elselect" @change="goFastFood()">
						<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.lable"
						:value="item.lable">
						</el-option>
					</el-select>
				</div>
				<div class="mq-state-box">
					<div class="mq-state">
						<template v-if="mqState==1">
							<span class="icon-box"><i class="dian"></i><i class="dian"></i><i class="dian"></i></span>重连中
						</template>
						<template v-else-if="mqState==2">
							<span class="icon-box"><i class="iconfont icon-a-zaixian-xian3x"></i></span>在线中
						</template>
						<template v-else>
							<span class="icon-box"><i class="iconfont icon-a-lixian-xian3x"></i></span>离线中
						</template>
					</div>
				</div>
			</div>
			<!-- <div class="left-txt">设备序列号:{{Site_Serial}}</div> -->
			<div class="flex-right">
				<div class="isLableData" v-if="$global.isLableData"><i class="iconfont icon-a-zhangdan3x"></i></div>
				<div class="print" @click="$router.push({name:'printReport'})">
					<div class="icon-img">
						<img src="/images/desk-printer.png"/>
						<img class="printer-yes" v-if="isPrintError" src="/images/desk-printer-yes.png"/>
					</div>
					<span class="name">打印机</span>
				</div>
				<div class="news">
					<div @click="isShowNew=true" >
						<i class="iconfont icon-xinxiaoxishezhi"></i><b class="news-number" v-show="newList?.length>0">{{newList.length}}</b>
						<i class="iconfont icon-a-desk-message" v-if="newList?.length>0"></i>
					</div>
					<div class="news-tip-box" v-show="isShowNew">
						<div class="tip-title">
							<div class="nav-bnt selected">数据同步消息</div>
							<!-- <div class="nav-bnt">通知消息</div> -->
						</div>
						<div class="tip-content">
							<el-empty description="" v-if="!newList || newList.length==0"></el-empty>
							<div class="li" v-for="(item,index) in newList" :key="item" v-else>
								<img class="icon" src="/images/news1.png"/>
								<div class="text nowrap2">{{index+1}}.{{item.text}}</div>
							</div>
							<!-- <div class="li">
								<img class="icon" src="/images/news1.png"/>促销方案数据修改，请操作【数据同步】
								<div class="text nowrap2">2.菜单信息修改，请操作【数据同步】</div>
							</div> -->
						</div>
						<div class="tip-bots">
							<div class="bnt" @click="isShowNew=false">返回</div>
							<div class="bnt bnt-confirm" @click="clickSync()">数据同步</div>
						</div>
					</div>
				</div>
				<div class="user"><i class="iconfont icon-yonghu1"></i><span>{{Login_Name}}</span></div>
				<div class="time"><i class="iconfont icon-shijian"></i><span id="currentTime">{{timeTxt}}</span></div>
				<div class="zuixiaohu"  @click="formMini()"><i class="iconfont icon-zuixiaohua"></i><span class="name">最小化</span></div>
				<template v-if="isWindow">
					<div class="zuixiaohu" v-if="isFull"  @click="formMax()"><i class="iconfont icon-tuichuquanping"></i><span class="name">退出全屏</span></div>
					<div class="zuixiaohu" v-else @click="formFull()"><i class="iconfont icon-quanping"></i><span class="name">全屏</span></div>
				</template>
			</div>
		</div>
		<div class="main">
			<div class="left-box">
				<div class="nav">
					<div class="li-bnt like-href"  v-for="menu in userNavMenu" :key="menu.key" :class="{selected:selectIndex==menu.tabIndex}" @click="navTab(menu.url)">
						<i class="iconfont "  :class="menu.icon"></i>
						<p>{{menu.name}}</p>
					</div>
				</div>
				<div class="nav food" v-show="$global.servingUrlType==1">
					<div class="li-bnt-Food" v-for="item in foodColorBtn" :key="item">
						<div class="color" :style="item.color"></div>
						<p>{{item.name}}</p>
					</div>
				</div>
				<button class="bnt_more" @click.stop="isMore=!isMore" @blur="isMore=false"><span class="iconfont icon-gengduo1"></span></button>
			</div>
			<div class="right-box">
				<router-view  v-on:navIndex="navIndex" v-on:lockScreen="isLockShow=true" v-on:backMenu="backMenu()" v-slot="{ Component }">
					<keep-alive :include="cacheName">
						<component :is="Component" />
					</keep-alive>
				</router-view>
			</div>
		</div>
		<div class="more" v-if="isMore" @click.stop @mousedown="(e)=>{e.preventDefault()}">
			<div class="title">更多功能</div>
			<div class="list-box clearfix">
				<div class="li" @click="logout()">
					<div class="icon"><i class="iconfont icon-zhuxiao"></i></div>
					<div class="name">注销</div>
				</div>
				<div class="li" @click="shutdown()" >
					<div class="icon"><i class="iconfont icon-guanji" ></i></div>
					<div class="name">关机</div>
				</div>
				<div class="li" @click="formMini()">
					<div class="icon"><i class="iconfont icon-zuixiaohua"></i></div>
					<div class="name">最小化</div>
				</div>
				<div class="li" @click="openWindKeyBoard()">
					<div class="icon"><i class="iconfont icon-jianpan1" style="font-size:18px"></i></div>
					<div class="name">系统键盘</div>
				</div>
				<div class="li" @click="close()">
					<div class="icon"><i class="iconfont icon-tuichu1"></i></div>
					<div class="name">退出程序</div>
				</div>
			</div>
		</div>
		<about-us-model :isShow="abotShow" v-on:closeModel="abotShow=false" ></about-us-model>
		<modal-load :isShow="isLockShow" :isUnload="true">
			<lock-screen-model :isShow="isLockShow" @closeModel="isLockShow=false" @routerLoad="$emit('routerLoad')" ></lock-screen-model>
		</modal-load>
		<modal-load :isShow="isCheckUserShow" :isUnload="true">
			<check-user-right-model :isShow="isCheckUserShow" @closeModel="isCheckUserShow=false" ref="checkUser"></check-user-right-model>
		</modal-load>

		<modal-load :isShow="isSelectShow" :isUnload="true">
			<select-Department-model :isShow="isSelectShow" @closeModel="isSelectShow=false" @routerLoad="$emit('routerLoad')" ></select-Department-model>
		</modal-load>
	</div>
</template>
<script>
import {newGuid} from '../../../src/common/index.js'
import { auth,cacheData } from '../../services';
import aboutUsModel from './aboutUs/aboutUsModel.vue'
import lockScreenModel from './lockScreenModel/lockScreenModel.vue';
import checkUserRightModel from './checkUserRightModel/checkUserRightModel.vue'
import selectDepartmentModel from './selectDepartmentModel/selectDepartmentModel.vue'

/**全局初始化数据 */
let _data={
    LockScreenSeconds:0, //启用触摸屏N秒后无操作自动锁屏(不填或0,则不启用)
};

const timeUserFun=(()=>{
	let t = _data.LockScreenSeconds;    // 默认参数
	if(t==0){
		t=86400; /**一天 */
	}
	console.log('进来的时间t:'+t)
	let userTime = t*1000;
	let timeFun=()=>{}
	let objTime = {
		init:0,
		time:function(){
			if(typeof(timeFun)=='function'){timeFun();}
		},
		eventFun:function(){
			clearTimeout(testUser);
			testUser = setTimeout(objTime.time,userTime);
		}
	}
	var testUser = setTimeout(objTime.time,userTime);
	var body = document.querySelector('html');
	body.addEventListener("click",objTime.eventFun);
	body.addEventListener("keydown",objTime.eventFun);
	body.addEventListener("mousemove",objTime.eventFun);
	body.addEventListener("mousewheel",objTime.eventFun);
	body.addEventListener("touchstart",objTime.eventFun);
	body.addEventListener("touchmove",objTime.eventFun);
	body.addEventListener("touchend",objTime.eventFun);
	return (time,fun)=>{
		if(time>0){
			userTime = time*1000;
			//userTime = time*1000*60;
		}
		timeFun=fun;
		objTime.eventFun();
	}
})();


export default {
	name: 'register',
	components:{
		aboutUsModel,
		lockScreenModel,
		checkUserRightModel,
		selectDepartmentModel
	},
	data(){
		let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
		if(globalSysSetting){
			if(globalSysSetting.LockScreenSeconds!=undefined){
				_data.LockScreenSeconds = globalSysSetting.LockScreenSeconds;
			}
		}
		return {
			/**是否是pc 设备 */
			isWindow:this.$webBrowser.isMobile.Windows,
			abotShow:false,
			/**是否锁屏 */
			isLockShow:false,
			/**是否显示路由 */
			isRouterAlive:true,
			//是否授权操作
			isCheckUserShow:false,
			/**更多操作 */
			isMore:false,
			Site_Serial:'',
			Login_Name:'',
			User_ID:'',
			Department_Name:'',
			Rpt_Date:'',
			//是否多门店
			IsAllowOperateMoreDepartments:false,
			isUnmout:false,
			timeTxt:'',
			selectIndex:-1,
			navMenu:{
				'Order':{key:'Order',name:'点单',icon:'icon-yudiancan',tabIndex:0,url:'diningTable'},
				'Bill':{key:'Bill',name:'账单',icon:'icon-zhangdan',tabIndex:1,url:'bill'},
				'Member':{key:'Member',name:'会员',icon:'icon-huiyuan',tabIndex:2,url:'member'},
				'Report':{key:'Report',name:'报表',icon:'icon-baobiao',tabIndex:3,url:'report'},
				'Config':{key:'Config',name:'设置',icon:'icon-shezhi',tabIndex:4,url:'setup'},
				CloudPosPre:{key:'CloudPosPre',name:'预订',icon:'icon-yuding',tabIndex:5,url:'cloudPosPre'},
			},
			KdsFoodColors:'', /**菜品状态 */
			foodColorBtn:[
				{name:'催菜',id:3,color:''},
				{name:'超时',id:5,color:''},
				{name:'临界',id:7,color:''},
				{name:'即起',id:2,color:''},
				{name:'等叫',id:1,color:''},
				{name:'划菜',id:23,color:''},
				{name:'上桌',id:24,color:''}
			],
			userNavMenu:[],
			options:[],
			fastFood: '',
			/**重新选择门店弹层 */
			isSelectShow:false,
			/**是否清除缓存页面 */
			isCleanCacheView:false,
			/**是否全屏 */
			isFull:!(this.$cacheData.get("fullScreen")===false),
			/**显示消息面板 */
			isShowNew:false,
			/**消息数据 */
			newList:[],
			//是否打印机异常
			isPrintError:false
		}
	},
	computed:{
		//mq在线状态
		mqState(){
			return this.$global.mqState;
		},
		//需要缓存的页面名称
		cacheName(){
			if(this.isCleanCacheView){
				return [];
			}
			return ["diningTable",'bill']
		},
		sysSetting(){
			return this.$cacheData?.globalVariable?.GlobalSysSetting;
		}
	},
	watch:{
		timeTxt(){//每分钟会变化一次
			this.setPromotion();
			this.$global.currentTime=new Date();//显示时间变化了
		},
		isFull(newVal){
			this.$cacheData.set("fullScreen",newVal);
		},
		"$global.printInfo":{
            handler(newVal){
				if(newVal && !newVal.Is_PrintOnline){
					this.isPrintError=true;
				}else{
					this.isPrintError=false;
				}
			},
            immediate:true
        },
		"sysSetting.CloudPosVoiceBroadcastSpeed":{//CloudPos语音播报语速
            handler(newVal){
				if(newVal){
					let speed=(newVal||4)*10;
					this.$webBrowser.ttsConfig(speed);
				}
			},
            immediate:true
        },
		"sysSetting.EverydayEmergencyDishFoods":{//每日急推菜ID集合
            handler(newVal){
				if(newVal){
					this.$global.EmergencyFoodIds=newVal;
				}
			},
            immediate:true
        }
	},
	mounted() {
		this.$global.printInfo=null;
		//菜品状态颜色结构
		this.$cacheData.KdsSettings().then((d)=>{
			this.KdsFoodColors=d.KdsFoodColors;
			this.foodColorBtn.map(food=>{
				this.KdsFoodColors.map(kds=>{
					if(food.id==kds.Speed_Status){
						food.color='background:'+kds.Color_Value+''
					}
					// if(food.id==24){
					// 	food.color='background:#FFFFFF;color:#000000'
					// }
				})
			})
		}).catch(e=>{
			console.log('e:'+e)
			this.$message.error('未找到菜品状态数据');
		})

		if(_data.LockScreenSeconds>0){
			timeUserFun(_data.LockScreenSeconds,()=>{
				if((this.$route.name=="diningTable" || this.$route.name=="diningDetail") && !this.isLockShow){
					this.isLockShow=true;
				}
			})
		}
		let userInfo=this.$auth.getUserInfo();
		if(userInfo){
			this.User_ID = userInfo.User_ID;
			this.Login_Name=userInfo.Login_Name;
			this.Department_Name=userInfo.Department_Name;
			this.Rpt_Date=userInfo.Rpt_Date;
			this.Site_Serial = userInfo.Site_Serial;
			this.options = userInfo.Business_Types;
			this.IsAllowOperateMoreDepartments=userInfo.IsAllowOperateMoreDepartments
		}

		let SysProjects=this.$cacheData.SysProjects.get();
		if(SysProjects && SysProjects.length>0){
			SysProjects.forEach(element=> {
				if(element.Visible){
					let menu=this.navMenu[element.Project_Key];
					if(menu){
						menu.name=element.Project_Name;
						menu.index=element.Project_Index;

						if(element.Project_Key=="CloudPosPre"){
							console.log(element)
							if(element.SysProjectGroups?.length>0){
								this.userNavMenu.push(menu);
							}
						}else{
							this.userNavMenu.push(menu);
						}	
					}
				}
			});
		}

		if(this.userNavMenu?.length>0){
			this.navTab(this.userNavMenu[0].url);
			this.selectIndex=this.userNavMenu[0].tabIndex;
		}

		this.$nextTick(()=> {
			//重新获取促销方案数据
			this.$cacheData.SalesPromotions();

			this.$mqTTClient.start();
			// 仅在渲染整个视图之后运行的代码
			this.isUnmout=false;
			this.timeShow();
		
			//监听营业日期
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => {
					resolve({Rpt_Date:this.Rpt_Date});
				});
			},"DayWorkInfoData",(d,newData)=>{
				this.Rpt_Date=newData.Rpt_Date;
				auth.setRpt_Date(newData.Rpt_Date);
			})

			//监听是否设备离线
			this.$webBrowser.on("posOffLine",(data)=>{
				this.$message.warning(data?.msg||"设备已离线");
				this.$auth.logout();
			});
			//菜品修改变动通知
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => {
					resolve({});
				});
			},"FoodChange_Notify",()=>{
				console.log("菜品修改变动通知")
				if(!this.newList.some(it=>it.type=='FoodChange_Notify')){
					this.newList.push({
						type:"FoodChange_Notify",//菜品修改变动通知
						text:"菜单信息修改，请操作【数据同步】",
					})
				}
			})

			
			//促销方案变动通知
			//促销价格变动通知数据DataInfo为空，不需要接受方解析数据，收到该类型的通知后，主动获取促销方案最新数据。
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => {
					resolve({});
				});
			},"SalesPromotionChange_Data",()=>{
				console.log("促销方案变动通知")
				if(!this.newList.some(it=>it.type=='SalesPromotions')){
					this.newList.push({
						type:"SalesPromotions",//促销方案变动通知
						text:"促销方案数据修改，请操作【数据同步】",
					})
				}
			})

			//外卖通知数据
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => { resolve({}); });
			},"TakeoutNotify",(d,newData)=>{
				console.log("TakeoutNotify",newData);
				/**播报次数 */
				if(newData?.Broadcast_Times>0){
					// 外卖数据变化了
					this.$EventBus.emit('TakeoutNotify');
					let broadData={title:newData.Broadcast_Info};
					if(newData.Notify_Type==1){//新进单
						broadData.musicType=1;
					}else if(newData.Notify_Type==2){//退单
						broadData.musicType=2;
					}else if(newData.Notify_Type==3){//异常单
						broadData.musicType=3;
					}
					for(let i=0;i<newData.Broadcast_Times;i++){
						this.$cacheData.addBroadcast(broadData,true);
					}
				}
			})

			//扫码买单通知数据
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => { resolve({}); });
			},"OnlineQrNotify",(d,newData)=>{
				console.log("OnlineQrNotify",newData);
				/**播报次数 */
				if(newData?.Broadcast_Times>0){
					let broadData={title:newData.Broadcast_Info};
					if(newData.Notify_Type==1){//新进单
						broadData.musicType=1;
					}else if(newData.Notify_Type==2){//退单
						broadData.musicType=2;
					}else if(newData.Notify_Type==3){//异常单
						broadData.musicType=3;
					}
					for(let i=0;i<newData.Broadcast_Times;i++){
						this.$cacheData.addBroadcast(broadData,true);
					}
				}
			});

			//厨房播报数据
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => { resolve({});});
			},"KitCaller_Data",(d,newData)=>{
				console.log("KitCaller_Data",newData)
				newData?.KitCallerInfos?.forEach(info=>{
					/**播报次数 */
					if(info?.Broadcast_Times>0){
						for(let i=0;i<info.Broadcast_Times;i++){
							this.$cacheData.addBroadcast(info.Broadcast_Info,true);
						}
					}
				})
			});
			//每日急推菜数据
			this.$mqTTClient.mqCloudPosData(()=>{
				return new Promise((resolve) => { resolve({});});
			},"EverydayEmergencyDish_Data",(d,newData)=>{
				console.log("EverydayEmergencyDish_Data",newData)
				this.$global.EmergencyFoodIds= newData.EmergencyFoodIds;
			});
		})

		//监听没有对应功能的权限时的请求
		this.$httpAES.permissionDenied((d,resolve,cancel)=>{
			this.$confirm(d.ResponseHeader.ResultDesc, '提示', {confirmButtonText: '继续验证',cancelButtonText: '我知道了',type: 'warning'})
			.then(() => {
				this.isCheckUserShow=true;
				this.$nextTick(()=>{
					this.$refs.checkUser.menuKey=d.ResponseHeader.Msg_Reserve;
					if(d.ResponseBody){
						this.$refs.checkUser.consumeID = d.ResponseBody.Check_ConsumeID;
						this.$refs.checkUser.checkNumber = d.ResponseBody.Check_Number;
						this.$refs.checkUser.Check_OrderID=d.ResponseBody.Check_OrderID;
					}
					if(d.ResponseBody?.CheckRightDetail){
						this.$refs.checkUser.checkNumber = d.ResponseBody?.CheckRightDetail.Check_Number
					}
					this.$refs.checkUser.cancel=()=>{
						this.isCheckUserShow=false;
						cancel();
					}
					this.$refs.checkUser.confirm=(d)=>{
						this.isCheckUserShow=false;
						resolve(d);
					}
				})
			}).catch(()=>{ 
				cancel();
			});
			
		})

		/**
		 * @param title 提示内容 
		 * @param param 参数
		 */
		this.$checkUser.show=(title,param)=>{
			return new Promise((resolve,reject) => {
				this.$confirm(title, '提示', {confirmButtonText: '去验证',cancelButtonText: '我知道了',type: 'warning'})
				.then(() => {
					this.isCheckUserShow=true;
					this.$nextTick(()=>{
						this.$refs.checkUser.menuKey=param.menuKey||param.Msg_Reserve;//用来验证的 key
						this.$refs.checkUser.consumeID =param.Check_ConsumeID;
						this.$refs.checkUser.checkNumber = param.Check_Number;
						this.$refs.checkUser.Check_OrderID=param.Check_OrderID;
						if(param?.CheckRightDetail){
							this.$refs.checkUser.checkNumber = param?.CheckRightDetail.Check_Number
						}
						this.$refs.checkUser.cancel=()=>{
							this.isCheckUserShow=false;
							reject();
						}
						this.$refs.checkUser.confirm=(d)=>{
							this.isCheckUserShow=false;
							resolve(d);
						}
					})
				}).catch(()=>{ 
					reject();
				});
			});
		}

		this.monitorPrint();
		//打印服务异常 语音播报
		this.$global.isPrintVoice=true;
	},
	beforeUnmount(){//被卸载前调用
		this.$data.isUnmout=true;
		this.$mqTTClient.close();
		this.$webBrowser.off("posOffLine");
		this.$webBrowser.off("TakeoutNotify");
		this.$webBrowser.off("OnlineQrNotify");
		this.$webBrowser.off("KitCaller_Data");
		if(this._printTime){
			clearTimeout(this._printTime);
		}
	},
	methods: {
		/**点击同步 */
		clickSync(){
			let leng=this.newList?.length||0;
			if(leng==0){
				this.$message.warning('没有可同步的数据');
				return;
			}
			
			const loading = this.$loading({
				text: "同步数据中...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			let count=0;
			let error=[];
			let endFun=(item)=>{
				try {
					count++;
					if(item){
						let index=this.newList.findIndex(it=>it.type==item.type);
						this.newList.splice(index,1);
					}
					if(count>=leng){
						loading.close();
						if(error && error.length){
							this.$message.error(error.join(";"));
						}else{
							this.$message.success('数据同步成功');
							this.isShowNew=false;
						}
					}
				} catch (error) {
					console.log(error);
				}
				
			}
			this.newList.forEach(it=>{
				if(it.type=="SalesPromotions"){
					//重新获取促销方案数据
					this.$cacheData.SalesPromotions(true).then((d)=>{
						console.log("促销方案请求数据",d);
						endFun(it);
					}).catch(e=>{
						console.log("促销方案请求数据错误",e);
						error.push("促销方案数据同步失败:"+e.mesage);
						endFun(it);
					})
				}else if(it.type=="FoodChange_Notify"){
					//刷新菜品数据
					this.$cacheData.RefreshPosFoods().then((d)=>{
						console.log("刷新菜品数据数据",d);
						endFun(it);
					}).catch(e=>{
						console.log("刷新菜品数据错误",e);
						error.push("菜品数据同步失败:"+e.mesage);
						endFun(it);
					})
				}else{
					endFun(it);
				}
			})
			
		},
		logout(){
			const loading = this.$loading({
				text: "退出登录中...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			let userInfo=this.$auth.getUserInfo();
			let param={
				Body_ID:newGuid(),
				User_ID:userInfo.User_ID, //登陆用户ID
				User_Name:userInfo.Login_Name
			}
			this.$httpAES.post("Bestech.CloudPos.LogoutPos",param).then(()=>{
				loading.close();
				this.$auth.logout();
			}).catch((e)=>{
				loading.close();
				this.$message.error('退出登录失败：'+e);
				console.log('退出登录失败：'+e);
			})
		},
		close() {
			this.confirm("是否确认关闭系统",()=>{
				const loading = this.$loading({
					text: "退出登录中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				let userInfo=this.$auth.getUserInfo();
				let param={
					Body_ID:newGuid(),
					User_ID:userInfo.User_ID, //登陆用户ID
					User_Name:userInfo.Login_Name
				}
				this.$httpAES.post("Bestech.CloudPos.LogoutPos",param).then((data)=>{
					loading.close();
					this.$webBrowser.close(false);
				}).catch((e)=>{
					loading.close();
					this.$message.error('退出登录失败：'+e);
					console.log('退出登录失败：'+e);
				})
			});
		},
		/**最小化 */
		formMini(){
			this.$webBrowser.formMini();
			this.isMore=false;
		},
		/**最大化 */
		formMax(){
			this.$webBrowser.formMax();
			this.isFull=false;
		},
		/**全屏 */
		formFull(){
			this.$webBrowser.formFull();
			this.isFull=true;
		},
		/**打开系统键盘 */
		openWindKeyBoard(){
			this.$webBrowser.openWindKeyBoard();
			this.isMore=false;
		},
		/***关机 */
		shutdown(){
			this.confirm("是否确认关机",()=>{
				const loading = this.$loading({
					text: "退出登录中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				let userInfo=this.$auth.getUserInfo();
				let param={
					Body_ID:newGuid(),
					User_ID:userInfo.User_ID, //登陆用户ID
					User_Name:userInfo.Login_Name
				}
				this.$httpAES.post("Bestech.CloudPos.LogoutPos",param).then((data)=>{
					loading.close();
					this.$webBrowser.shutdown(false);
				}).catch((e)=>{
					loading.close();
					this.$message.error('退出登录失败：'+e);
					console.log('退出登录失败：'+e);
				})
			});
		},
		//导航显示  子页面要用 this.$emit("navIndex",0) 调用父页面的navIndex 传入当要 tabIndex是那个导航下的
		navIndex(tabIndex){
			this.selectIndex=tabIndex||0;
			if(this.$route.name=="fastFoodDetail" ){
				this.fastFood="快餐";
			}else{
				this.fastFood="中餐";
			}
			
		},
		ImgClick(){
			this.abotShow = true;
			// let requestWait=this.$requestWait.show("测试");
			// setTimeout(()=>{
			// 	requestWait.hide();
			// },3000)
		},
		timeShow(){
			//用来显示时间的方法
			if(this.isUnmout) return;
			let date = new Date();
			let month = "00" + (date.getMonth() + 1);
			let day = "00" + date.getDate();
			let hours = "00" +date.getHours();//小时 
			let minutes = "00" + date.getMinutes(); //分 
			let seconds = date.getSeconds();//秒
			let days = date.getDay();//星期
			let week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
			this.timeTxt = month.substr(month.length - 2) + "月" + day.substr(day.length - 2) + "日 " + week[days] + " "
				+ hours.substr(hours.length - 2) + ":" + minutes.substr(minutes.length - 2);
			setTimeout(()=>{this.timeShow();}, (60 - seconds) * 1000);
		},
		navTab(pathname){
			if(pathname=='diningTable' && this.options?.length>0){
				if(this.options[0].value=='1'){
					this.$router.push({
						path: 'fastFoodDetail',name:'fastFoodDetail'
					});
				}else if(this.options[0].value=='0'){
					this.$router.push('/home/diningTable');
				}
			}else{
				if(pathname){
					this.$router.push('/home/'+pathname);
				}else{
					this.$router.push('/home/diningTable');
				}
			}
		},
		goFastFood(){
			if(this.fastFood=='快餐'){
				this.$router.push({
					path: 'fastFoodDetail',name:'fastFoodDetail'
				});
			}else{
				this.$router.push({
					path: 'diningTable',name:'diningTable'
				});
			}
		},
		confirm(title,configFun){
			this.$confirm(title, "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm'){
						configFun();
					}
				},
			});
		},
		/**返回当前栏目页 */
		backMenu(){
			let navMenu=this.userNavMenu?.find(it=>it.tabIndex==this.selectIndex);
			if(navMenu){
				if(navMenu.url=="diningTable"){
					this.goFastFood();
				}else{
					this.navTab(navMenu.url);
				}
			}else if(this.userNavMenu?.length>0){
				this.navTab(this.userNavMenu[0].url);
			}
		},
		/**监听 促销方案 菜品 时段 价格 变化 */
		setPromotion(){
			this.$cacheData.updatePromotionFood();
		},
		/** 监听打印机 状态 */
		monitorPrint(){
			if(this._printTime){
				clearTimeout(this._printTime);
			}
			let userInfo=this.$auth.getUserInfo();
			if(userInfo?.Is_PrintServer){
				this.$httpAES.post("Bestech.CloudPos.GetPrintServer",{}).then((d)=>{
					console.log(d)
					if(d.ResponseHeader.ResultCode==0){
						if(d.ResponseBody?.Site_Serial==userInfo?.Site_Serial){
							this.$global.printInfo=d.ResponseBody;//打印服务信息 放入全局变量
							if(!d.ResponseBody.Is_PrintOnline){
								this.isPrintError=true;
								this.printErrorVoice(); 
							}else{
								this.isPrintError=false;
							}
							this._printTime=setTimeout(this.monitorPrint,60*1000);
						}else{
							this.isPrintError=false;
							this.$global.printInfo=null;
						}
					}else{
						this._printTime=setTimeout(this.monitorPrint,60*1000);
					}
				}).catch((e)=>{
					this._printTime=setTimeout(this.monitorPrint,60*1000);
					console.error('获取打印服务站点数据失败:',e);
				})
			}
		},
		//打印机异常 语音播报
		printErrorVoice(){
			if(this.isPrintError && this.$global.isPrintVoice){
				this.$webBrowser.tts("打印机服务异常");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	@import './home.scss'
</style>
