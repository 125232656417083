<template>
    <div class="withMenuEdit">
        <div class="flex-box">
            <div class="order-list">
                <div class="content-box">
                    <div class="stretch-box">
                        <div class="panel-heading">
                            <div class="td name">名称</div>
                            <div class="td tdnum">数量</div>
                            <div class="td tds">单价</div>
                            <div class="td subtotal">小计</div>
                            <div class="td category">菜品类别</div>
                        </div>
                        <div class="scroll-box" ref="box" v-mouse-scroll v-scroll-anime="{up:'order-up2',down:'order-down2',liClass:'.slide-wrapper'}">
                            <div class="slide-wrapper" :class="{selectedRow:this.Recordvarie==item}" v-for="(item,index) in editOrderList" :key="item">
                                <div class="index">{{index+1}}</div>
                                <div class="slide-content" @click="readyClick(item,index)">
                                    <div class="tr">
                                        <div class="td name nowrap">{{item.Eat_XFName}}
                                            <div class="more" v-if="item.FoodSetmeals?.length>0" style="display:inline-block;">
                                                <div class="more-bnt" v-if="editEatXFBMID!=item.Eat_ConsumeID" @click="editEatXFBMID=item.Eat_ConsumeID">
                                                    <i class="iconfont icon-xiala"></i>
                                                </div>
                                                <div class="more-bnt" v-else @click="editEatXFBMID=null">
                                                    <i class="iconfont icon-shangla"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="td tdnum">{{item.Eat_Number}}</div>
                                        <div class="td tds">¥{{Conversion(item.Eat_CheckPrice)}}/{{(item.Eat_XFSize||"").substring(0,1)}}</div>
                                        <div class="td subtotal nowrap">{{Conversion((item.Eat_CheckPrice * item.Eat_Number))}}</div>
                                        <div class="td category nowrap">{{item.SubCategory_Name}}</div>
                                    </div>
                                    <div class="describe flex-box">
                                        <div class="inlineBlock nowrap2 flavor">
                                            <span v-if="item.Eat_MasName" :title="item.Eat_MasName" >
                                                <i class="iconfont icon-a-beizhu2" ></i>
                                                口味:<span>{{item.Eat_MasName}}</span>
                                            </span>
                                        </div>
                                        <div class="inlineBlock tag-box">
                                            <!--套-->
                                            <span class="tag tao" :style="{visibility:(item.FoodProperty_Dimension?'':'hidden')}" >{{item.FoodProperty_Dimension||'套'}}</span>
                                            <!--厨房状态-->
                                            <span class="tag delimit" v-if="item.Food_Speed">{{item.Food_Speed}}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="border"></div>
                                <el-collapse-transition>
                                <div class="more-box" v-show="item.FoodSetmeals?.length>0 && editEatXFBMID==item.Eat_ConsumeID">
                                    <div class="slide-content" :class="{selected:food.Eat_XFBMID == disRadioOrder?.Eat_XFBMID && food.Eat_XFName== disRadioOrder?.Eat_XFName}"  
                                        :title="item.Eat_XFName" 
                                        v-for="(food) in item.FoodSetmeals" 
                                        :key="food" 
                                        @click="EatTcClick(food,item)" >
                                        <div class="tr">
                                            <div class="td name nowrap">{{food.Eat_XFName}}</div>
                                            <div class="td tdnum">{{food.Eat_Number}}</div>
                                            <div class="td tds" >¥{{Conversion(food.Eat_CheckPrice)}}/{{(food?.Eat_XFSize||"份").substring(0,1)}}</div>
                                            <div class="td subtotal nowrap" >{{Conversion(food.Eat_CheckPrice * food.Eat_Number) }}</div>
                                            <div class="td category nowrap">{{food.SubCategory_Name}}</div>
                                        </div>
                                        <div class="describe flex-box">
                                            <div class="inlineBlock flavor nowrap2" >
                                                <span v-if="food.Eat_MasName!=''" :title="food.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" 
                                                    @click="EatTcClick(food,item);">
                                                    <i class="iconfont icon-a-beizhu2" ></i>
                                                    口味:<span>{{food.Eat_MasName}}</span>
                                                </span>
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <!--厨房状态-->
                                                <span class="tag delimit" v-if="food.Food_Speed">{{food.Food_Speed}}</span>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                </el-collapse-transition>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer orderFooter">
                    <div class="float-left txt">
                        <span class="money" v-html="deskTotal.total"></span>&nbsp;
                        <span class="small">共{{deskTotal.sum}}道 &nbsp; {{deskTotal.sumPortion}}份</span>
                    </div>
                    <div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
                        <div class="bnt-fangxiang" ref="order-up2"><i class="iconfont icon-fangxiangquan-top"></i></div>
                        <div class="bnt-fangxiang" ref="order-down2"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
                    </div>
                </div>
            </div>
            <div class="operate-button">
                <div class="scroll scrollnot">
                    <div class="button-group">
                        <button class="btn" @click="Delete(Recordindex,Recordvarie)" ><div>单删</div></button><br/>
                        <button class="btn" @click="kitchenClick()"><div>厨房</div></button><br/>
                        <button class="btn" @click="cancelDoSoldOut()"><div>要求</div></button><br/>
                        <button class="btn" @click="Add()"><div><b class="iconfont icon-jia"></b></div></button><br/>
                        <button class="btn" @click="reduce()"><div><b class="iconfont icon-jian"></b></div></button><br/>
                        <button class="btn" @click="Numberadjust()"><div>数量</div></button><br/>
                        <button class="btn" @click="DiyTcClick()"><div>即配套餐</div></button><br/>
                        <button class="btn" @click="tcChangeFood()"><div>套餐换菜</div></button><br/> 
                    </div>
                </div>
            </div>

            <div class="right-box">
                <div class="panel-body">
                    <div class="filter-nav"  v-if="orderMap_Type==2 ||orderMap_Type==1">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodNavPage',isNavFirstSticky:true}">
                            <div class="nav-li" :class="{selected:mainCategoryIndex==0}" @click="onFoodNav()">全部大类</div>
                            <div class="nav-li" :class="{selected:mainCategoryIndex==index+1}" v-for="(item,index) in foodMainCategory" :key="item" @click="onFoodNav(item,index+1)">{{item.MainCategory_Name}}</div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                            <span class="page-number">{{foodNavPage.pageIndex}}/{{foodNavPage.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down"></span>
                        </div>
                    </div>
                    <div>
                        <div class="filter-nav" v-if="orderMap_Type==3 ||orderMap_Type==1">
                            <div class="nav-box" v-scroll-anime="{up:'nav-sub-up',down:'nav-sub-down',fx:'x',liClass:'.nav-li',page:'foodNavPageSub',isNavFirstSticky:true}">
                                <div class="nav-li" :class="{selected:subCategoryIndex==0}" @click="onFoodSubNav()">全部小类</div>
                                <div class="nav-li" :class="{selected:subCategoryIndex==index+1}" v-for="(item,index) in foodSubCategorys" :key="item" @click="onFoodSubNav(item,index+1)">{{item.SubCategory_Name}}</div>
                            </div>
                            <div class="float-right page-box">
                                <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-sub-up" ></span>
                                <span class="page-number">{{foodNavPageSub.pageIndex}}/{{foodNavPageSub.pageSize}}页</span>
                                <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-sub-down"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel menu">
                    <!-- 菜单 -->
                    <layout-rc class="item-box clearfix" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :isVarCss="true">
                        <div class="item-li " :class="{selected:item.Food_ID==choicePosFoods}" v-for="item in foodsPages"  :key="item" @click="onPlaceFoods(item,1)">
                            <div class="title">
                                {{item.Food_Name}}
                            </div>
                            <div class="bottom-box">
                                <span class="text-left" v-if="item.Is_Limited  && item.Is_LimitedProject">剩: {{item.RemainNum}}</span>
                                <span class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</span>
                            </div>
                            <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                            <div class="tag tag-grey" v-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                            <div class="tag" v-if="item.isDiy">自定义</div>
                        </div>
                    </layout-rc>
                </div>
                <div class="search-box">
                    <key-board-input class="search"
                        type="text" v-model="varietySelect" placeholder="菜品模糊检索" ref="searchBox"
                        isKeyEnterClose isBlurClose
                        @keydown="keyCursor"
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                    <div class="float-right">
                        <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                        <span class="page-number">{{ posFoodsPage.pageIndex }}/{{ posFoodsPage.pageSize }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-box">
            <div class="main-box">
                <div class="title"><span>主信息</span></div>
                <div class="tr-li">
                    <div class="lable-txt"  style="width:80px">名 &nbsp;&nbsp;&nbsp;&nbsp; 称：</div>
                    <div class="from-input" style="width:200px"><input type="text" ref="menuName" v-model="Eat_MenuName" v-board></div>
                    <div class="lable-txt">人 数：</div>
                    <div class="from-input" style="width:110px">
                        <key-board-input type="text" pattern="number" :dplaces="0" :min="1" 
                            :keyOptions="{isNumber:true,isKeyDownEnter:true}"
                            isBlurClose isKeyEnterClose
                            v-model="Eat_ManNum" v-board />
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt" style="width:80px">订餐桌号：</div>
                    <div class="from-input select-desk" style="width:200px" @click="choiceDeskShow=true">
                        <input type="text" v-model="Eat_DeskName" readonly/>
                        <i class="iconfont icon-zhuotai"></i>
                    </div>
                    
                    <div class="lable-txt">席 数：</div>
                    <div class="from-input" style="width:110px">
                        <key-board-input ref="deskNum" type="text" pattern="number" :dplaces="0" :min="1" 
                            :keyOptions="{isNumber:true,isKeyDownEnter:true}"
                            isBlurClose isKeyEnterClose
                            v-model="Eat_DeskNum" v-board />
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt" style="width:80px">备 &nbsp;&nbsp;&nbsp;&nbsp; 注：</div>
                    <div class="from-input" style="width:200px"><input type="text" v-model="Order_Remark" v-board></div>
                    <div class="from-input isPrivat" style="flex:1">
                        <label @click="IsPrivateMenu=!IsPrivateMenu">
                            <i class="iconfont" :class="(IsPrivateMenu?'icon-xuanze1':'icon-xuanze')"></i>私有配菜
                        </label>
                    </div>
                </div>
                <div class="tr-li">
                    注：服务费，优惠金额仅供配菜单打印显示，实际消费按当时账单金额为准。
                </div>
            </div>
            <div class="money-box">
                <div class="title"><span>配菜金额</span></div>
                <div class="tr-li">
                    <div class="lable-txt">(－) 优惠额</div>
                    <div class="from-input" style="flex:1">
                        <key-board-input ref="zkMoney" type="text" pattern="number" :dplaces="2" 
                            :keyOptions="{isNumber:true,isKeyDownEnter:true}"
                            isBlurClose isKeyEnterClose
                            v-model="Eat_ZKMoney" v-board />
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">(＋) 服务费</div>
                    <el-switch v-model="Is_ServiceFee" :inline-prompt="true" size="large" active-color="#0ED557" active-text="" inactive-text=""></el-switch>
                </div>
                <div class="tr-li" :style="{visibility:Is_ServiceFee?'':'hidden'}">
                    <el-select class="kind" v-model="Eat_ServiceKind">
                        <el-option :value="1" label="按比例收取"></el-option>
                        <el-option :value="2" label="按固定收取"></el-option>
                    </el-select>
                    <div class="from-input select-box" v-show="Eat_ServiceKind==1">
                        <el-dropdown trigger="click">
                            <div class="el-dropdown-link">
                                <input-pattern @click.stop v-board type="text" pattern="number" :dplaces="0" :min="1" v-model="Eat_ServiceChargeRate" />
                                <i class="iconfont icon-fangxiang-down"></i>
                            </div>
                            <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="item in [10,15,20]" :key="item" 
                                    :class="{selected:item==Eat_ServiceChargeRate}" 
                                    @click="Eat_ServiceChargeRate=item">{{item}}</el-dropdown-item>
                            </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <span> &nbsp;%</span>
                    </div>
                    <div class="serviceMoney" v-show="Eat_ServiceKind==1">
                        <span class="unit">￥</span><span class="num">{{perMoney}}</span>
                    </div>
                    <div class="from-input" style="flex:1" v-show="Eat_ServiceKind==2">
                        <key-board-input type="text" pattern="number" :dplaces="2" placeholder="请输入服务费" 
                            :keyOptions="{isNumber:true,isKeyDownEnter:true}"
                            isBlurClose isKeyEnterClose
                            v-model="Eat_ServiceCharge" v-board />
                    </div>
                </div>
                <div class="tr-li">
                    <span>&nbsp;(＝) &nbsp;</span>
                    <div class="money">
                        应付额 <span class="unit">￥</span><span class="num" v-html="parMoneyHtml"></span>
                    </div>
                    <div class="gm" v-show="ProfitRate==true">GM:{{orderComplete}}%</div>
                </div>
            </div>
        </div>
        <!--菜品换单位-->
        <modal-load :isShow="unitShow">
            <change-Unit-Model :isShow="unitShow" v-on:closeModel="unitShow=false;$refs.searchBox.focus()" :multipleSizeFoods="multipleSizeFoods" @onPlaceFoods="onPlaceFoods" ></change-Unit-Model>
        </modal-load>
        <!-- 套餐 -->
        <modal-load :isShow="setMealShow" :isUnload="true">
            <set-Meal-model :isShow="setMealShow" v-on:closeModel="setMealShow=false;$refs.searchBox.focus()" 
                :isNotReplace="true"
                :posFoodSetMealInfos="posFoodSetMealInfos" @setMealClick="setMealClick" 
                :disRadioOrder="Recordvarie" :tcChangeType="isEditMeal?1:null"> </set-Meal-model>
        </modal-load>
        <!--口味要求-->
        <modal-load :isShow="demandFullShow">
            <readydemand-model :isShow="demandFullShow" v-on:closeModel="demandFullShow=false;$refs.searchBox.focus()" :allfoodSubCategorys="allfoodSubCategorys" :readyorderMenuList="editOrderList" :masKindsList="masKindsList" :disRadioOrder="disRadioOrder?disRadioOrder:Recordvarie" @confirmall="confirmall" >
            </readydemand-model>
        </modal-load>
        <!-- 自定义菜品 -->
        <modal-load :isShow="diyFoodsShow">
            <diy-foods-model :isShow="diyFoodsShow" v-on:closeModel="diyFoodsShow=false,$refs.searchBox.focus()" :data="diyFoodInfo" @confirm="difConfirm" > </diy-foods-model>
        </modal-load>
        <!--数量调整-->
        <modal-load :isShow="adjustShow">
            <adjust-quantity-model :isShow="adjustShow" v-on:closeModel="adjustShow=false;$refs.searchBox.focus()" :Recordvarie="Recordvarie" @quantityReturn="quantityReturn"></adjust-quantity-model>
        </modal-load>
        <!--厨房-->
        <modal-load :isShow="kitchenShow">
            <ready-kitchen-model :isShow="kitchenShow"  v-on:closeModel="kitchenShow=false;$refs.searchBox.focus()" :readyorderMenuList="editOrderList" :allfoodSubCategorys="allfoodSubCategorys" @kitchenClickBack="kitchenClickBack"></ready-kitchen-model>
        </modal-load>
         <!--即配套餐-->
        <modal-load :isShow="readyShow">
            <ready-to-use-package-Model :isShow="readyShow" v-on:closeModel="readyShow=false;$refs.searchBox.focus()" 
                :readyAllfoodSubCategorys="allfoodSubCategorys" 
                :readyShowType="readyShowType" 
                @readyPackageReturn="readyPackageReturn" 
                :isNoFilter="true"
                :disRadioOrder="readyShowType==0?{}:Recordvarie" 
                @ReadycloseModel="ReadycloseModel" >
            </ready-to-use-package-Model>
        </modal-load>
        <!--选择桌号-->
        <modal-load :isShow="choiceDeskShow">
            <choice-Desk-Model
                :isShow="choiceDeskShow"
                @closeModel="choiceDeskShow=false;$refs.searchBox.focus()"
                @deskclose="deskclose"
            ></choice-Desk-Model>
        </modal-load>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </div>
</template>

<script>
import { cacheData } from '/src/services'
import { newGuid,animation,deepClone,NumberConversion } from '/src/common/index.js'
import changeUnitModel from '../changeUnitModel/changeUnitModel.vue'
import setMealModel from '../setMealModel/setMealModel.vue'
import diyFoodsModel from '../readyToUsePackageModel/diyFoodsModel/diyFoodsModel.vue'
import adjustQuantityModel from '../readyToUsePackageModel/adjustQuantityModel/adjustQuantityModel.vue'
import readyKitchenModel from '../readyToUsePackageModel/readyKitchenModel/readyKitchenModel.vue'
import readydemandModel from '../readyToUsePackageModel/readydemandModel/readydemandModel.vue'
import readyToUsePackageModel from '../readyToUsePackageModel/readyToUsePackageModel.vue'
import choiceDeskModel from "../../fastFoodDetail/choiceDeskModel/choiceDeskModel.vue";

/**全局初始化数据 */
let _data={
    RunOrderDeviceShowBillProfitRate:false /**启用点单设备显示账单毛利率，true: 显示，false:不显示 */
};
//编辑配菜单
export default {
    name:"withMenuEdit",
    components:{
        changeUnitModel,
        setMealModel,
        diyFoodsModel,
        adjustQuantityModel,
        readyKitchenModel,
        readydemandModel,
        readyToUsePackageModel,
        choiceDeskModel
    },
    props:{
        /**需要编辑的数据 */
        data:{
            default:null
        }
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunOrderDeviceShowBillProfitRate = globalSysSetting.RunOrderDeviceShowBillProfitRate;
        }
        return {
            //是否服务费
            Is_ServiceFee:false,
            //收取服务费方式 0 不收取服务费 1 按比例收取服务费 2 按固定金额收取服务费
            Eat_ServiceKind:1,
            /**服务费率 */
            Eat_ServiceChargeRate:0,
            /**服务费 */
            Eat_ServiceCharge:0,
            //优惠金额
            Eat_ZKMoney:'',
            //配菜名称
            Eat_MenuName:"",
            //备注
            Order_Remark:"",
            //是否只创建人编辑
            IsPrivateMenu:false,
            //席数
            Eat_DeskNum:1,
            //人数
            Eat_ManNum:1,
            //桌台名称
            Eat_DeskName:'',
            //桌台ID
            Eat_DeskID:"",
            //配菜单数据
            editOrderList:[],
            //套餐下拉显示用
            editEatXFBMID:'',
            //自定义菜品数据
            PosDiyFoodsData:[],
            //pos菜品数据
            PosFoodsData:{},
            arg:{row:5,col:4},
            mainCategoryIndex:0,
            /**小类数据 */
            foodSubCategorys:[],
            subCategoryIndex:0,
            /**菜品数据  */
            posFoods:[],
            /**菜品页码 */
            posFoodsPage:{
                pageIndex:1,
                pageSize:1
            },
            foodNavPage:{pageIndex:0,pageSize:0},
            foodNavPageSub:{pageIndex:0,pageSize:0},
            /**菜品检索 */
            varietySelect:"",
            //菜品大类小类 显示 类型
            orderMap_Type:'',
            /**选择的菜品 */
            choicePosFoods:null,
            //记录左边点击的菜品
            Recordvarie:'',
            //菜品序列号
            Recordindex:-1,
            //选中的菜品
            disRadioOrder:'',  
            //菜品换单位 弹层显示
            unitShow:false,
            //多规格菜品数据
            multipleSizeFoods:null,
            //套餐 弹层显示
            setMealShow:false,
            //是否修改套餐
            isEditMeal:false,
            /**对应套餐数据 */
            posFoodSetMealInfos:[],
            //口味要求 弹层显示
            demandFullShow:false,
            /**菜品口味 */
            masKindsList:'',
            //自定义菜品 弹层显示
            diyFoodsShow:false,
            //选中的自定义菜品 
            diyFoodInfo:null,
            //数量调整 弹层显示
            adjustShow:false,
            //厨房 弹层显示
            kitchenShow:false,
            //即配套餐 弹层显示
            readyShow:false,
            //1修改即配套餐,0新增即配套餐 
            readyShowType:0,
            ProfitRate:false,//启用点单设备显示账单毛利率，true: 显示，false:不显示
            //选择桌号 弹层
            choiceDeskShow:false
        }
    },
    mounted(){
       this.$nextTick(()=> {
            this.$cacheData.DeskPosconfigure().then((d)=>{
                this.orderMap_Type = d.OrderMap_Type
                this.ProfitRate = _data.RunOrderDeviceShowBillProfitRate
            }).catch(e=>{
                this.$alert("基本配置获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
            this.init()
        })
    }, 
    computed:{
        info(){
            let info={};
            if(this.data){
                Object.assign(info,this.data);
            }
            if(this.Is_ServiceFee){//是否服务费
                if(this.Eat_ServiceKind==1){//按比例
                    info.Eat_ServiceKind=1;
                    info.Eat_ServiceChargeRate=(this.Eat_ServiceChargeRate||0)/100;
                    info.Eat_ServiceCharge=this.perMoney;
                }else{
                    info.Eat_ServiceKind=2;
                    info.Eat_ServiceCharge=this.Eat_ServiceCharge||0;
                }
            }else{
                info.Eat_ServiceKind=0;//收取服务费方式
                info.Eat_ServiceChargeRate=0;//服务费率
                info.Eat_ServiceCharge=0;//服务费
            }

            Object.assign(info, {
                Eat_DeskID:this.Eat_DeskID,//桌台ID
                Eat_DeskName:this.Eat_DeskName,//桌台名称
                Eat_ManNum:this.Eat_ManNum||0,//人数
                Eat_ZKMoney:this.Eat_ZKMoney||0,//优惠金额
                Eat_MenuName:this.Eat_MenuName,//名称
                Order_Remark:this.Order_Remark,//备注
                Eat_DeskNum:this.Eat_DeskNum,//席数
                IsPrivateMenu:this.IsPrivateMenu,//是否私有
                Eat_CheckMoney:this.payMoney,//应付金额
                Eat_SaleMoney:Number(this.deskTotal.placeFoodsTotal),//菜品金额
                FoodSetmeals:this.editOrderList
            });
            return info;
        },
        /**全部 口味数据 */
        AllMasKinds(){
            let list=[];
            if(this.masKindsList){
                this.masKindsList.forEach((kinds)=>{
                    list=list.concat(kinds.EatMasList);
                })
            }
            return list;
        },
        //菜品原数据
        posFoodsData(){
            let data=[];
            let d=this.PosFoodsData?.data;
            if(d && d.length>0){
                data=JSON.parse(JSON.stringify(this.PosFoodsData?.data));
                data?.forEach(category=> {
                    category.FoodSubCategorys?.forEach(sub=>{
                        sub.PosFoods?.forEach(food=>{
                            food.Is_SoldOut=false;//清除沽清状态
                            food.MultipleSizeFoods?.forEach(it=>{
                                it.Is_SoldOut=false;
                            });
                            food.SetMealInfo?.SetMealKinds?.forEach(meal=>{
                                meal.SetMealDetails?.forEach(it=>{
                                    it.Is_SoldOut=false;
                                })
                            })
                        })
                    })
                })
            }
            return data;
        },
        //大类数据
        foodMainCategory(){
            let data=this.posFoodsData;
            
            if(data && data.length>0){

                let diyFoodSubCategorys=Object.assign([],this.PosDiyFoodsData);//未合并进去的自定义菜品
                diyFoodSubCategorys.forEach(it=> 
                    it.PosFoods?.forEach((f)=>{
                        f.isDiy=true;
                    })
                )
                if(this.PosDiyFoodsData && this.PosDiyFoodsData.length>0){//合并 自定义菜品
                    data.forEach(category=> {
                        category?.FoodSubCategorys?.forEach(sub => {
                            let index=diyFoodSubCategorys.findIndex(f=>f.SubCategory_ID==sub.SubCategory_ID);
                            if(index>=0){
                                let difSub=diyFoodSubCategorys[index];
                                diyFoodSubCategorys.splice(index,1);//剔除
                                if(difSub && difSub.PosFoods?.length>0){
                                    sub.PosFoods=sub.PosFoods.concat(difSub.PosFoods);
                                }
                            }
                            
                        });
                    });
                }

                let all=[];//全部大类
                data.forEach(category=> {
                    if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                        all=all.concat(category.FoodSubCategorys);
                    }
                });
                if(diyFoodSubCategorys?.length>0){//将未合并进去的自定义菜品 分类都合并到全部小类中
                    all=all.concat(diyFoodSubCategorys);
                }
                
                all._all=[];//全部小类
                if(all.length>0){
                    all.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        all._all=all._all.concat(sub.PosFoods)
                    });
                }
                data._all=all; //小分类
            }
            return data;
        },
        /**临时菜品数据 */
        TempposFoods(){
            return this.foodMainCategory?._all||[];
        },
        //全部小类数据
        allfoodSubCategorys(){
            let subCategorys=[];
            this.foodMainCategory?.forEach((category)=>{
                category?.FoodSubCategorys?.forEach(sub=>{
                    if(sub){
                        let _SubCategory={SubCategory_Name:sub.SubCategory_Name,SubCategory_ID:sub.SubCategory_ID}
                         subCategorys.push(_SubCategory);
                    }
                })
            })
            return subCategorys;
        },
        /**菜品分页显示 */
        foodsPages(){
             let size=this.arg.row*this.arg.col;
            return this.posFoods.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
        /**计算账单总金额 数量 份数 */
        deskTotal(){
            let placeFoodsTotal = 0;
            let placeFoodsSumPortion = 0;
            let placeFoodsSum=0;
            let saleMoney=0;//账单总销售金额
            let costMoney=0; //账单总成本金额
            this.editOrderList?.forEach(item=>{
                if(item.Eat_Number>0){
                    placeFoodsSum++; //总数量
                    if(item.Food_Type!=4){
                        placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(item.Eat_Number); //总份量
                    }
                    if(item.ModiPrice){
                        placeFoodsTotal = placeFoodsTotal+((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number);  //总价格
                        saleMoney +=((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number);
                    }else{
                        placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number);  //总价格
                        saleMoney +=item.Eat_CheckPrice * item.Eat_Number;
                    }
                    if(item.Food_CostPrice>0){
                        costMoney += Number(item.Food_CostPrice) * Number(item.Eat_Number);
                    }
                    item.FoodModifys?.forEach(modi=>{//口味
                        if(Number(modi.Mas_Price)>0){//加价口味做法
                            let modifysMoney=0;
                            if(modi.Price_Kind==0){
                                modifysMoney=Number(modi.Mas_Price) * Number(item.Eat_Number);
                                modi.Mas_Num=item.Eat_Number;
                            }else if(modi.Price_Kind==1){
                                modifysMoney=Number(modi.Mas_Price);
                                modi.Mas_Num=1;
                            }else if(modi.Price_Kind==2 && this.Eat_ManNum>0){
                                modifysMoney=Number(modi.Mas_Price) * this.Eat_ManNum;
                                modi.Mas_Num=this.Eat_ManNum;
                            }
                            placeFoodsTotal+=modifysMoney;
                        }
                    })
                    item.FoodSetmeals?.forEach(eat=>{
                        if(item.Food_Type!=4){
                            placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(eat.Eat_Number); //总份量
                        }
                        if(item.ModiPrice){
                            placeFoodsTotal = placeFoodsTotal+((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number);  //总价格
                            saleMoney +=((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number) ;
                        }else{
                            placeFoodsTotal = placeFoodsTotal+(eat.Eat_CheckPrice * eat.Eat_Number); //总价格
                            saleMoney += eat.Eat_CheckPrice * eat.Eat_Number; 
                        }

                        if(eat.Food_CostPrice>0){
                            costMoney += Number(eat.Food_CostPrice) * Number(eat.Eat_Number);
                        }

                        eat.FoodModifys?.forEach(modi=>{//口味
                            if(Number(modi.Mas_Price)>0){//加价口味做法
                                let modifysMoney=0;
                                if(modi.Price_Kind==0){
                                    modi.Mas_Num=Number(eat.Eat_Number);
                                    modifysMoney=Number(modi.Mas_Price) * Number(eat.Eat_Number);
                                }else if(modi.Price_Kind==1){
                                    modifysMoney=Number(modi.Mas_Price);
                                    modi.Mas_Num=1;
                                }else if(modi.Price_Kind==2 && this.Eat_ManNum>0){
                                    modifysMoney=Number(modi.Mas_Price) * this.Eat_ManNum;
                                    modi.Mas_Num=this.Eat_ManNum;
                                }
                                placeFoodsTotal+=modifysMoney;
                            }
                        })
                    })
                    
                }
            })
            return {
                costMoney:Number(costMoney.toFixed(2)),//成本金额 计算毛利率用 不包含加价口味
                saleMoney:Number(saleMoney.toFixed(2)),//销售金额 计算毛利率用 不包含加价口味
                placeFoodsTotal:Number(placeFoodsTotal.toFixed(2)),//销售金额
                total:'<i>￥</i>'+placeFoodsTotal.toFixed(2).replace(/[.]\d*/g,(s)=>{return '<b>'+s+'</b>'}),//总金额
                sumPortion: parseFloat(placeFoodsSumPortion.toFixed(2)),//份数
                sum:placeFoodsSum//数量
            }
        },
        //毛利率理算
        orderComplete(){
            let achieving=0;
            if(this.deskTotal.saleMoney>0){
                achieving =(this.deskTotal.saleMoney-this.deskTotal.costMoney)/this.deskTotal.saleMoney;
            }

            return Number(achieving * 100).toFixed(2);
        },
        /**服务费按比例计算 */
        perMoney(){
            if(this.Eat_ServiceKind==1){
                return Number(((Number(this.deskTotal.placeFoodsTotal)*this.Eat_ServiceChargeRate)/100).toFixed(2));
            }
            return 0;
        },
        //计算应付金额
        payMoney(){
            let totalMoney=this.deskTotal.placeFoodsTotal;
            let Eat_ZKMoney=0;
            let serviceMoney=0;
            if(this.Eat_ZKMoney>0){//优惠金额
                Eat_ZKMoney=this.Eat_ZKMoney;
            }
            if(this.Eat_ServiceKind==1 && this.perMoney>0){
                serviceMoney=this.perMoney;
            }else if(this.Eat_ServiceKind==2 && this.Eat_ServiceCharge>0){
                serviceMoney=this.Eat_ServiceCharge;
            }
            
            return Number(totalMoney).compute(Eat_ZKMoney,'-').compute(serviceMoney,'+');
        },
        parMoneyHtml(){
            return this.payMoney.toFixed(2).replace(/[.]\d*/g,(s)=>{return '<b class="decimal">'+s+'</b>'});
        }
    },
    watch:{
        /**数据变化时 初始化数据 */
        data:{
            handler(){
                if(this.data){
                    let d=deepClone(this.data);
                    console.log(d)
                    this.ProfitRate = _data.RunOrderDeviceShowBillProfitRate
                    if(d.FoodSetmeals){
                        this.Eat_DeskID=d.Eat_DeskID;//桌台ID
                        this.Eat_DeskName=d.Eat_DeskName;//桌台名称
                        this.Eat_ManNum=d.Eat_ManNum;//人数
                        this.Eat_ServiceChargeRate=0;//服务费率
                        this.Eat_ServiceCharge=0;//服务费
                        
                        if(d.Eat_ServiceKind==0){//收取服务费方式
                            this.Is_ServiceFee=false;
                        }else if(d.Eat_ServiceKind==1){
                            this.Is_ServiceFee=true;
                            this.Eat_ServiceKind=1;//收取服务费方式
                            this.Eat_ServiceChargeRate=d.Eat_ServiceChargeRate*100;
                        }else{
                            this.Is_ServiceFee=true;
                            this.Eat_ServiceKind=2;//收取服务费方式
                            this.Eat_ServiceCharge=d.Eat_ServiceCharge;
                        }
                        this.Eat_ZKMoney=d.Eat_ZKMoney;//优惠金额
                        
                        this.Eat_MenuName=d.Eat_MenuName;//名称
                        this.Order_Remark = d.Order_Remark;//备注
                        this.Eat_DeskNum=d.Eat_DeskNum;//席数
                        this.IsPrivateMenu=d.IsPrivateMenu;//是否只创建人编辑
                       
                        //this.editOrderList=d.FoodSetmeals;//配菜单数据
                        this.orderCompleteGross(d.FoodSetmeals)
                    }
                }
            },
            immediate:true
        },
        /**菜品搜索 */
        varietySelect(newVal,oldVal){
            if(newVal && (newVal.indexOf('+')>=0 || newVal.indexOf('-')>=0 )){
                this.varietySelect=oldVal;
                return;
            }
            if(this.varietySelect!=''){
                this.subCategoryIndex=0;
                this.mainCategoryIndex=0;
                this.posFoods=[];
                let MenuTxt=this.varietySelect.toLowerCase();
                this.$nextTick(()=>{
                    if(this.foodsPages && this.foodsPages.length>0){
                        this.choicePosFoods=this.foodsPages[0].Food_ID;
                    }
                })
                this.posFoods=(this.foodMainCategory?._all?._all||[]).filter(pos=>
                    (pos.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 
                    || (pos.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0 
                    || (pos.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0
                )
            }else{
                this.posFoods =this.foodMainCategory?._all?._all||[];
            }
        },
        posFoods(){
            let size=5*4;
            this.posFoodsPage.pageIndex=1;
            let pageSize=Math.ceil(this.posFoods.length/size);
            if(pageSize==0){
                pageSize=1;
            }
            this.posFoodsPage.pageSize=pageSize;
        }
    },
    methods:{
        keyinfo(e){
            console.log('进来了:')
        },
        keyup(e){
            if(e.key=="+" || e.code=="NumpadAdd"){
                this.Add()
                this.$msgbox.close();
            }else if(e.key=="-" || e.code=="NumpadSubtract"){
                this.reduce()
                this.$msgbox.close();
            }
            //else if(e.key=="Escape" && this.isEdit==true){
            //    this.isEdit=false
            //}
        },
         /**初始化 */
        init(){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            let isLoadFoods=false,isLoadDiyFoods=false;
            let nextFun=()=>{
                if(isLoadFoods && isLoadDiyFoods){
                    this.$nextTick(()=>{
                        this.onFoodNav();

                        if(this.data){
                            let d=deepClone(this.data);
                            this.ProfitRate = _data.RunOrderDeviceShowBillProfitRate
                            if(d.FoodSetmeals){
                                this.Eat_MenuName=d.Eat_MenuName;//名称
                                this.Order_Remark = d.Order_Remark;//备注
                                this.Eat_DeskNum=d.Eat_DeskNum;//席数
                                this.IsPrivateMenu=d.IsPrivateMenu;//是否只创建人编辑
                                //this.editOrderList=d.FoodSetmeals;//配菜单数据
                                this.orderCompleteGross(d.FoodSetmeals)
                            }
                        }

                        this.editOrderList?.map((item)=>{
                            if(!item.SubCategory_Name){
                                item.SubCategory_Name=this.TempposFoods?.find(allfood=>allfood.PosFoods?.find((food)=>food.Food_ID==item.Eat_XFBMID))?.SubCategory_Name||"";
                            }
                        })
                    })
                }
            }
            //菜品数据结构
            this.$cacheData.PosFoods().then((d)=>{
                this.PosFoodsData=d;
                isLoadFoods=true;
                nextFun();
            }).catch(e=>{
                isLoadFoods=true;
                console.log('e:'+JSON.stringify(e))
                this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
            })

            /**自定义菜品数据结构 */
            this.$cacheData.PosDiyFoods().then((d)=>{
                this.PosDiyFoodsData=d;
                isLoadDiyFoods=true;
                nextFun();
            }).catch(e=>{
                isLoadDiyFoods=true;
                console.log('e:'+e)
                this.$alert('未找到自定义菜品数据', "提示", {confirmButtonText: "确定"});
            })

            /**菜品口味 */
            this.$cacheData.MasKinds().then((d)=>{
                this.masKindsList = d;
                this.setFoodModifys();
            }).catch(e=>{
                this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**控制菜品搜索框 上下左右光标 */
        keyCursor(e){
            if(e.keyCode=='37' || e.keyCode=='38' || e.keyCode=='39' || e.keyCode=='40' || e.keyCode=='13'){
                e.preventDefault();
                if(this.foodsPages && this.foodsPages.length>0){
                    let index=this.foodsPages.findIndex(item=>item.Food_ID==this.choicePosFoods)||0;
                    if(e.keyCode=='37'){//左边
                        index--;
                    }else if(e.keyCode=='38'){//上
                        index=index-this.arg.col
                    }else if(e.keyCode=='39'){//右边
                        index++;
                    }else if(e.keyCode=='40'){//下
                        index=index+this.arg.col;
                    }else if(e.keyCode=='13'){
                        if(this.foodsPages.length>0){
                            this.onPlaceFoods(this.foodsPages[index],1)
                        }else{
                            this.$message.warning('未找到菜品');
                            return
                        }
                        return;
                    }
                    if(index>=0 && index<this.foodsPages.length){
                        this.choicePosFoods=this.foodsPages[index].Food_ID;
                    }
                }
            }
            if(e.key=="+" || e.code=="NumpadAdd"){
                this.Add()
                this.$msgbox.close();
            }
            if(e.key=="-" || e.code=="NumpadSubtract"){
                this.reduce()
                this.$msgbox.close();
            }
            if(e.key=="Escape"){
                this.isEdit==false
            }
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posFoodsPage.pageIndex<this.posFoodsPage.pageSize){
                    this.posFoodsPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posFoodsPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posFoodsPage.pageIndex<=1?1:this.posFoodsPage.pageIndex--;
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                if(!item.FoodSubCategorys._all){
                    item.FoodSubCategorys._all=[];//全部菜品
                    item.FoodSubCategorys.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        item.FoodSubCategorys._all=item.FoodSubCategorys._all.concat(sub.PosFoods)
                    });
                }
                this.foodSubCategorys=item.FoodSubCategorys;
                this.mainCategoryIndex=index;
                this.onFoodSubNav();
            }else if(this.foodMainCategory && this.foodMainCategory._all){//全部
                this.foodSubCategorys=this.foodMainCategory._all;
                this.mainCategoryIndex=0;
                this.onFoodSubNav();
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.subCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.subCategoryIndex=0
            }
            this.varietySelect="";
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**菜品点击*/
        onPlaceFoods(item,type){
            if(item?.isDiy && type==1){//点击自定菜品
                this.diyFoodsShow=true;
                this.diyFoodInfo=item;
                this.choicePosFoods = item.Food_ID;
                return;
            }
            //分类名称
            let _SubName=this.TempposFoods?.find(allfood=>item.SubCategory_ID==allfood.SubCategory_ID)?.SubCategory_Name||"";
            
            console.log(_SubName,this.allfoodSubCategorys)
            this.Recordvarie=null
            this.isEditMeal=false
            /**判断是否多规格菜品 */
            if(item.Is_MultipleSize){
                if(item.Is_MultipleSize==true){
                    this.unitShow=true
                    this.multipleSizeFoods = item
                    return
                }
            }
            this.unitShow=false
            let _item='';
            this.choicePosFoods = item.Food_ID;
            /**是否是套餐 */
            if(item.Is_SetMeal){
                this.posFoodSetMealInfos=[]
                this.posFoodSetMealInfos.push(item);
                this.setMealShow=true;
            }else{
                let readType=0
                if(!item?.isDiy){
                    this.editOrderList.map((read,i)=>{
                        if(read.Eat_XFBMID==item.Food_ID){
                            read.Eat_Number= Number(read.Eat_Number)+1
                            readType=1
                            this.readyClick(read,i);
                        }
                    })
                }
                
                if(readType==0){
                    _item ={
                        SubCategory_ID:item.SubCategory_ID,
                        Eat_XFBMID:item.Food_ID,
                        Eat_XFCode:item.Food_Code,
                        Eat_XFName:item.Food_Name,
                        Consume_TypeID:'',
                        Eat_Number:1,
                        Eat_CheckPrice:item.Food_Price,
                        PriceSubtotal: item.Food_Price,
                        ModiPrice:0,
                        Eat_SalePrice:item.Food_Price,
                        Food_CostPrice:item?.Food_CostPrice,
                        Eat_MasName:'',
                        Food_Status:-1,
                        Eat_XFSize:item.Food_Size,
                        Eat_AssNum:0,
                        Eat_AssSize:item.Eat_AssSize||"",
                        FoodModifys:[],
                        FoodSetmeals:null,
                        Is_Discounted:item.Is_Discounted,
                        Is_Seasonal:item.Is_Seasonal,
                        RemainNum:item.RemainNum,
                        Is_Limited:item.Is_Limited,
                        Is_ChangedNum:item.Is_ChangedNum,
                        Is_ChargeForService:item.Is_ChargeForService,
                        SubCategory_Name:_SubName,
                        Is_SetMeal:item.Is_SetMeal,
                        base_Number:1,
                        Food_Speed:"",
                        Food_Type:1,//单菜
                        //indenttion:newGuid(),
                        Eat_ConsumeID:newGuid()
                    };
                    if(item.isDiy){
                        _item.Eat_Number= item.Eat_Number;
                        _item.Eat_MasName=item.Eat_MasName;
                        _item.FoodModifys=item.FoodModifys;
                    }
                    this.editOrderList.push(_item);
                    this.readyClick(_item,this.editOrderList.length-1);
                }
            }
            this.varietySelect=''
        },
        /**左边菜单点击 */
        readyClick(item,index){
            this.Recordvarie=item;
            this.Recordindex=index;
            this.disRadioOrder = null;
            this.$nextTick(()=>{
                animation.scrollTopAnchor(this.$refs.box,".slide-wrapper.selectedRow")
            })
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**套餐下菜品点击 */
        EatTcClick(food,item){
            this.disRadioOrder = food;
            this.Recordvarie=item;
        },
        /**套餐返回 */
        setMealClick(setMealNum,posFoodSetMeal){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            /**1.套餐换菜返回,清理掉之前的 */
            if(this.isEditMeal){
                this.Delete(this.Recordindex,this.Recordvarie);
            }
            this.setMealShow = false;
            let _FoodSetmeals=[];
            let food_Number=1;
            let _setNumber= setMealNum
            /**添加到订单中 */
            posFoodSetMeal.SetMealInfo.SetMealKinds.map(item=>{
                item.SetMealDetails.map(food=>{
                    if(food.Is_Selected){
                        if(posFoodSetMeal?.Is_SetMealCountAvgPrice){
                            _setNumber = 1
                        }
                        _FoodSetmeals.push({
                            SetMealDetail_ID:food.SetMealDetail_ID,
                            Eat_XFBMID:food.Food_ID,
                            Eat_XFCode:food.Food_Code,
                            Eat_XFName:food.Food_Name,
                            Eat_Number:food.Food_Number * _setNumber,
                            Eat_XFSize:food.Food_Size,
                            Eat_AssNum:0,
                            Eat_AssSize:"",
                            Eat_CheckPrice:food.Food_TcPrice,
                            Food_CostPrice:food?.Food_CostPrice,
                            FoodModifys:[],
                            Eat_MasName:'',
                            ModiPrice:0,
                            Food_Status:-1,
                            base_Number:food.Food_Number * _setNumber,
                            Eat_ConsumeID:newGuid()
                        })
                    }
                })
            })
            let _SubName="";//菜品类别
            this.allfoodSubCategorys?.map(allfood=>{
                if(posFoodSetMeal.SubCategory_ID==allfood.SubCategory_ID){
                    _SubName=allfood.SubCategory_Name
                }
            })
            let _item={
                SubCategory_ID:posFoodSetMeal.SubCategory_ID,
                Eat_XFBMID:posFoodSetMeal.Food_ID,
                Eat_XFCode:posFoodSetMeal.Food_Code,
                Eat_XFName:posFoodSetMeal.Food_Name,
                Consume_TypeID:"",
                Eat_Number:setMealNum,
                Eat_CheckPrice:posFoodSetMeal.Food_Price,
                PriceSubtotal:posFoodSetMeal.Food_Price * setMealNum,
                ModiPrice:0,
                Eat_SalePrice:posFoodSetMeal.Food_Price * setMealNum,
                Food_CostPrice:posFoodSetMeal?.Food_CostPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_Speed:'',
                Eat_XFSize:posFoodSetMeal.Food_Size,
                Eat_AssNum:0,
                Eat_AssSize:posFoodSetMeal.Eat_AssSize||"",
                FoodModifys:[],
                FoodSetmeals:_FoodSetmeals,//套餐数据
                Is_Discounted:posFoodSetMeal.Is_Discounted,
                Is_Seasonal:posFoodSetMeal.Is_Seasonal,
                RemainNum:posFoodSetMeal.RemainNum,
                Is_Limited:posFoodSetMeal.Is_Limited,
                Is_ChangedNum:posFoodSetMeal.Is_ChangedNum,
                Is_ChargeForService:posFoodSetMeal.Is_ChargeForService,
                SubCategory_Name:_SubName,
                Is_SetMeal:posFoodSetMeal.Is_SetMeal,
                base_Number:food_Number,
                FoodProperty_Dimension:'套',
                Food_Type:2,//套餐
                Eat_ConsumeID:newGuid(),
                //indenttion:newGuid(),
                Is_SetMealCountAvgPrice:false
            };
            if(posFoodSetMeal?.Is_SetMealCountAvgPrice){
                _item.Is_SetMealCountAvgPrice = posFoodSetMeal?.Is_SetMealCountAvgPrice
            }
            this.editOrderList.push(_item);
            this.readyClick(_item,this.editOrderList.length-1);
        },
        /**套餐换菜 */
        tcChangeFood(){
            if(!this.Recordvarie.FoodSetmeals){
                this.$message.error('请选择对应套餐');
                return;
            }
            if(this.Recordvarie.Food_Type==3){//即配套餐换菜
                Object.assign(this.Recordvarie,{
                    EAT_XFBMID:this.Recordvarie.Eat_XFBMID,
                    EAT_XFCode:this.Recordvarie.Eat_XFCode,
                    EAT_XFName:this.Recordvarie.Eat_XFName,
                })
                this.Recordvarie.FoodSetmeals?.map((food)=>{
                    if(!food.SubCategory_ID){
                        let subCategorys=this.TempposFoods.find(sub=>sub.PosFoods?.find(item=>item.Food_ID==food.Eat_XFBMID));
                        if(subCategorys){
                            food.SubCategory_Name=subCategorys.SubCategory_Name;
                            food.SubCategory_ID=subCategorys.SubCategory_ID;
                        }
                    }
                    Object.assign(food,{
                        EAT_XFBMID:food.Eat_XFBMID,
                        EAT_XFCode:food.Eat_XFCode,
                        EAT_XFName:food.Eat_XFName,
                        indenttion:food.indenttion||newGuid()
                    })
                });
                this.readyShowType=1;
                this.readyShow=true;
            }else{
                //格式转换
                this.Recordvarie.FoodSetmeals.map((food)=>{
                    Object.assign(food,{
                        FoodSetmeal_ID:food.Eat_XFBMID,
                        FoodSetmeal_Code:food.Eat_XFCode,
                        FoodSetmeal_Name:food.Eat_XFName,
                        FoodSetmeal_Number:food.Eat_Number,
                        FoodSetmeal_Price:0,
                        FoodSetmeal_Size:food.Eat_XFSize,
                        FoodSaleMoney:food.Eat_CheckPrice,
                    })
                })
                this.TempposFoods?._all?.some(pos=>{
                    if(pos.Food_ID==this.Recordvarie.Eat_XFBMID){
                        this.posFoodSetMealInfos=[]
                        this.posFoodSetMealInfos.push(pos);
                        return true
                    }
                })
                this.isEditMeal=true;
                this.setMealShow = true;
            }
        },
        /**菜品添加 */
        Add(){
            if(this.Recordvarie){
                let EatNumber=Number(this.Recordvarie.Eat_Number);
                this.Recordvarie.Eat_Number = Number(Number(Number(this.Recordvarie.Eat_Number) + Number(this.Recordvarie.base_Number||1)).toFixed(2));
                //套餐
                this.Recordvarie?.FoodSetmeals?.map(food=>{
                    if(this.Recordvarie?.Is_SetMealCountAvgPrice==false){
                        if(!food.base_Number){
                            food.base_Number = Number((food.Eat_Number / EatNumber)).toFixed(2);
                        }
                        food.Eat_Number= Number(Number(Number(food.Eat_Number)+Number(food.base_Number)).toFixed(2))
                    }
                })
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**菜品减少 */
        reduce(){
            if(this.Recordvarie){

                if(Number(this.Recordvarie.Eat_Number)>Number(this.Recordvarie.base_Number||1)){
                    let EatNumber=Number(this.Recordvarie.Eat_Number);
                    this.Recordvarie.Eat_Number = this.accSub(Number(this.Recordvarie.base_Number||1),this.Recordvarie.Eat_Number);
                    //套餐
                    this.Recordvarie?.FoodSetmeals?.map(food=>{
                        if(this.Recordvarie?.Is_SetMealCountAvgPrice==false){
                            if(!food.base_Number){
                                food.base_Number = Number((food.Eat_Number / EatNumber)).toFixed(2);
                            }
                            food.Eat_Number= this.accSub(Number(food.base_Number||1), Number(food.Eat_Number));
                        }
                    })
                }
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return  Number(((arg2*m-arg1*m)/m).toFixed(n));
        },
        /**要求 */
        cancelDoSoldOut(){
            if(this.Recordvarie){
                //disRadioOrder?disRadioOrder:Recordvarie

                if(this.disRadioOrder){
                    this.disRadioOrder.FoodModifys?.forEach(modifys=>{
                        let kind=this.AllMasKinds.find(kind=>kind.Mas_AutoID==modifys.Mas_AutoID);
                        if(kind){
                            Object.assign(modifys,kind);
                        }
                    })
                }else{
                    this.Recordvarie.FoodModifys?.forEach(modifys=>{
                        let kind=this.AllMasKinds.find(kind=>kind.Mas_AutoID==modifys.Mas_AutoID);
                        if(kind){
                            Object.assign(modifys,kind);
                        }
                    })
                }
                this.demandFullShow = true;
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**菜品要求返回 */
        confirmall(optFlavors,temporderMenuList){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            let _foodModifys=[];
            let _Eat_MasName ='';
            optFlavors.map(num=>{
                _Eat_MasName += num.Mas_Name+'/';
                if(num.Is_AddMoney==true){
                    //按量加价
                    if(num.Price_Kind==0){
                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                    }
                    if(num.Price_Kind==1){
                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                    }
                }else{
                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                }
                
            })
            
            if(this.Recordvarie){
                //套餐
                if(this.disRadioOrder){
                    _foodModifys.map(num=>{
                        if(num.Price_Kind==0){
                            num.Mas_Num=this.disRadioOrder.Eat_Number;
                        }
                    });
                    this.disRadioOrder.Eat_MasName=_Eat_MasName;
                    this.disRadioOrder.FoodModifys=_foodModifys;
                }else{
                    _foodModifys.map(num=>{
                        if(num.Price_Kind==0){
                            num.Mas_Num=this.Recordvarie.Eat_Number;
                        }
                    });
                    this.Recordvarie.Eat_MasName=_Eat_MasName;
                    this.Recordvarie.FoodModifys=_foodModifys;
                }
            }
            if(temporderMenuList){
                temporderMenuList.forEach(tm=>{
                    if(tm?.Eat_ConsumeID){
                        if(tm.Eat_ConsumeID==this.Recordvarie?.Eat_ConsumeID){
                            tm.is_type=0
                        }
                    }
                })
                this.synchMethods(optFlavors,temporderMenuList)
            }
            this.demandFullShow = false
        },
        synchMethods(optFlavors,temporderMenuList){
            /**同步菜品 */
            temporderMenuList.map(temp=>{ 
                this.editOrderList.map((order)=>{
                    if(temp?.Eat_ConsumeID==order?.Eat_ConsumeID && temp.is_type==1){
                        let _foodModifys=[];
                        let _Eat_MasName ='';
                        optFlavors.map(num=>{
                            _Eat_MasName += num.Mas_Name+'/';
                            if(num.Is_AddMoney==true){
                                //按量加价
                                if(num.Price_Kind==0){
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:order.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                }
                                if(num.Price_Kind==1){
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                }
                            }else{
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                            }
                        })
                        order.Eat_MasName += _Eat_MasName;
                        if(!order.FoodModifys){
                            order.FoodModifys=[]
                        }
                        _foodModifys.forEach(fm=>{
                            order.FoodModifys.push(fm);
                        })
                    }else if(order.FoodSetmeals){//套餐
                        order.FoodSetmeals.map((food)=>{
                            if(food.Eat_XFBMID==temp.FoodSetmeal_ID && temp.is_type==1){
                                let _foodModifys=[];
                                let _Eat_MasName ='';
                                optFlavors.map(num=>{
                                    _Eat_MasName += num.Mas_Name+'/';
                                    if(num.Is_AddMoney==true){
                                        //按量加价
                                        if(num.Price_Kind==0){
                                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:food.FoodSetmeal_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                        }
                                        if(num.Price_Kind==1){
                                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                        }
                                    }else{
                                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                    }
                                    
                                })
                                food.Eat_MasName += _Eat_MasName;
                                if(!food.FoodModifys){
                                    food.FoodModifys=[]
                                }
                                _foodModifys.forEach(fm=>{
                                    food.FoodModifys.push(fm);
                                })
                            }
                        })
                    }
                })
            })
        },
        /**删除 */
        Delete(index,item){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            if(index>=0){
                this.editOrderList.splice(index,1);
                if(this.editOrderList.length==0){
                    this.Recordvarie=null;
                    this.Recordindex = -1;
                    return;
                }
                if(index!=0){
                    this.readyClick(this.editOrderList[index-1],index-1);
                }else{
                    this.readyClick(this.editOrderList[index],index);
                }
            }
        },
        /**厨房点击 */
        kitchenClick(){
            this.editOrderList?.forEach((item)=>{
                Object.assign(item,{
                    EAT_XFBMID:item.Eat_XFBMID,
                    EAT_XFCode:item.Eat_XFCode,
                    EAT_XFName:item.Eat_XFName,
                    Food_Status:-1
                })
                item.FoodSetmeals?.map((food)=>{
                    Object.assign(food,{
                        FoodSetmeal_ID:food.Eat_XFBMID,
                        FoodSetmeal_Code:food.Eat_XFCode,
                        FoodSetmeal_Name:food.Eat_XFName,
                        FoodSetmeal_Number:food.Eat_Number,
                    })
                })
            })
            this.kitchenShow=true;
        },
        /**厨房返回 */
        kitchenClickBack(temporderMenuList){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            temporderMenuList.map(item=>{
                this.editOrderList.map(order=>{
                    if(item.EAT_XFBMID==order.Eat_XFBMID){
                        if(!item.FoodSetmeal_ID || item.EAT_XFBMID==item.FoodSetmeal_ID){
                             order.Food_Speed = item.Food_Speed;
                        }
                        else{
                            order.FoodSetmeals?.map(food=>{
                                if(item.FoodSetmeal_ID==food.Eat_XFBMID){
                                    food.Food_Speed=item.Food_Speed;
                                }
                            })
                        }
                    }
                })
            })
            
            this.kitchenShow =false;
        },
        Numberadjust(){
            if(this.Recordvarie){
                Object.assign(this.Recordvarie,{
                    EAT_XFBMID:this.Recordvarie.Eat_XFBMID,
                    EAT_XFCode:this.Recordvarie.Eat_XFCode,
                    EAT_XFName:this.Recordvarie.Eat_XFName,
                })
                this.adjustShow=true
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**数量返回 */
        quantityReturn(number){
            this.adjustShow = false;
            let EatNumber=this.Recordvarie.Eat_Number;
            this.Recordvarie.Eat_Number=number;
            this.Recordvarie.FoodSetmeals?.map(food=>{
                if(food.base_Number){
                    food.base_Number = food.Eat_Number / EatNumber;
                }
                food.Eat_Number= Number(Number(number*Number(food.base_Number||1)).toFixed(2));
            })
        },
        //点击即配套餐
        DiyTcClick(){
            this.readyShowType=0;
            this.readyShow=true;
        },
        /**即配套餐新增返回 */
        readyPackageReturn(DiyTcInfo){
            this.readyShow = false;
            this.subShow = false;
            if(DiyTcInfo.FoodModifys && Object.keys(DiyTcInfo.FoodModifys).length==0){
                DiyTcInfo.FoodModifys=null
            }
            DiyTcInfo.FoodSetmeals?.map(food=>{
                if(food.FoodModifys && Object.keys(food.FoodModifys).length==0){
                    food.FoodModifys=null
                }
            })
            DiyTcInfo.Consume_TypeID="";
            //菜品类型 ，1：单菜 ，2：套餐，3：临时套餐 4：加价口味要求 ，5：拼盘
            DiyTcInfo.Food_Type=3;
            DiyTcInfo.Eat_XFBMID=DiyTcInfo.EAT_XFBMID;
            DiyTcInfo.Eat_XFCode=DiyTcInfo.EAT_XFCode;
            DiyTcInfo.Eat_XFName=DiyTcInfo.EAT_XFName;
            DiyTcInfo.FoodSetmeals?.map((item)=>{
                item.Eat_XFBMID=item.FoodSetmeal_ID;
                item.Eat_XFCode=item.FoodSetmeal_Code,
                item.Eat_XFName=item.FoodSetmeal_Name;
                item.Eat_Number=item.FoodSetmeal_Number;
                item.Eat_CheckPrice=0;
                item.Eat_XFSize=item.FoodSetmeal_Size;
                item.base_Number=0;
            })
            console.log(DiyTcInfo)
            if(this.readyShowType==1){
                //菜品要求 还原
                if(this.Recordvarie.Eat_MasName){
                    DiyTcInfo.Eat_MasName=this.Recordvarie.Eat_MasName;
                    DiyTcInfo.FoodModifys=this.Recordvarie.FoodModifys;
                }
                //厨房数据还原
                if(this.Recordvarie.Food_Speed){
                    DiyTcInfo.Food_Speed=this.Recordvarie.Food_Speed;
                }
                
                this.editOrderList[this.Recordindex] = DiyTcInfo;
                this.readyClick(DiyTcInfo,this.Recordindex);
            }else{
                this.editOrderList.push(DiyTcInfo);
                this.readyClick(DiyTcInfo,this.editOrderList.length-1);
            }
        },
        /**拿到菜品的成本价,计算毛利率 */
        orderCompleteGross(FoodSetmeals){
            this.foodSubCategorys.forEach(food=>{
                food.PosFoods.forEach(pos=>{
                    if(FoodSetmeals.length>0){
                        FoodSetmeals.forEach(order=>{
                            if(order.Eat_XFBMID==pos.Food_ID){
                                order.Food_CostPrice=pos.Food_CostPrice;//成本价
                                
                                order.FoodSetmeals?.forEach(it=>{//套餐
                                    pos.SetMealInfo?.SetMealKinds?.some(kinds=>{
                                        return kinds?.SetMealDetails.some(food=>{
                                            if(food.Food_ID==it.Eat_XFBMID){
                                                it.Food_CostPrice=food.Food_CostPrice;//成本价
                                                return true;
                                            }
                                        })
                                    })
                                })
                            }else if(pos.Is_MultipleSize){//多规格
                                pos?.MultipleSizeFoods.forEach(mu=>{
                                    if(order.Eat_XFBMID==mu.Food_ID){
                                        order.Food_CostPrice=mu.Food_CostPrice;//成本价
                                    }
                                })
                            }
                        })
                    } 
                })
            })
            this.editOrderList = FoodSetmeals;
            this.setFoodModifys();
        },
        //还原菜品数据 口味信息
        setFoodModifys(){
            if(this.editOrderList?.length>0 && this.AllMasKinds.length>0){
                console.log(this.AllMasKinds)
                //还原口味数据
                this.editOrderList?.forEach(it=>{
                    it.FoodModifys?.forEach(mod=>{
                        let kind=this.AllMasKinds.find(kind=>kind.Mas_AutoID==mod.Mas_AutoID);
                        if(kind){
                            Object.assign(mod,{
                                Price_Kind:kind.Price_Kind,
                                Is_AddMoney:kind.Is_AddMoney,
                                Mas_Price:kind.Mas_Price,
                                Mas_Code:kind.Mas_Code,
                                Mas_Name:kind.Mas_Name
                            })
                        }
                    })
                    it.FoodSetmeals?.forEach(eat=>{
                        eat.FoodModifys?.forEach(mod=>{//口味
                            let kind=this.AllMasKinds.find(kind=>kind.Mas_AutoID==mod.Mas_AutoID);
                            if(kind){
                                Object.assign(mod,{
                                    Price_Kind:kind.Price_Kind,
                                    Is_AddMoney:kind.Is_AddMoney,
                                    Mas_Price:kind.Mas_Price,
                                    Mas_Code:kind.Mas_Code,
                                    Mas_Name:kind.Mas_Name
                                })
                            }
                        });
                    })
                })
            }
        },
        /**即配套餐取消*/
        ReadycloseModel(){
            this.readyShow = false;
        },
        //编辑自定义菜品点击确认
        difConfirm(item){
            this.diyFoodsShow=false;
            item.isDiy=true;
            this.onPlaceFoods(item,2);
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num);
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox,{isKeyDown:true});
                //this.$refs.keyBoard.show(input,{type:type,isKeyDown:true});
                this.$refs.searchBox.focus();
            }  
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
        /**桌台选择返回 */
		deskclose(desk) {
			this.Eat_DeskName = desk.Desk_Name;
			this.Eat_DeskID = desk.Desk_AutoID;
			this.choiceDeskShow = false;
		},
    }
}
</script>

<style>

</style>