<template>
<modal class="servingDeskModel" :isShow="isShow" @keyup.esc="CloseClicks" >
    <div class="Popup-Title">
        <span class="Popup-Title-left">选择桌台</span>
        <div class="float-left search">
            <div class="DeskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                <span class="iconfont icon-jianpan"></span>
            </div>
            <input type="text" placeholder="桌号检索" v-model="DeskAutoSelce" ref="searchBox" @blur="searchInputBlur" />
        </div>
    </div>
    <div class="filter-nav clearfix">
        <div class="nav-box"  v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage'}">
            <div class="nav-li" :class="{ selected: filternav == -1 }" @click="NavigationClick(-1)" >全部</div>
            <div class="nav-li" :class="{ selected: filternav ==index }" v-for="(item,index) in PosRooms" :key="item"  @click="NavigationClick(index)" >
                {{item.Room_Name}}
            </div>
        </div>
        <div class="float-right page-box">
            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
            <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
        </div>
    </div>
    <div class="desk content clearfix" style="width: 100%;height: 40%;overflow: auto;">
        <div class="desk-box opening" v-for="item in getDeskList" :key="item" @click="DeskMergeClick(item)">
            <div class="top" v-if="item.OpenDeskInfo" >
                <span>{{item.OpenDeskInfo.Person_Num}}人</span>
                <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span> 
            </div>
            <p class="title">{{item.Desk_Name}}</p>
            <div class="bottom">
                <span v-if="item.OpenDeskInfo">{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> 
                <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_CheckMoney}}</span>
            </div>
        </div> 
    </div>
    <div class="Popup-InterTxt">
        <span>请确认选择的桌台号</span>
        <el-radio-group v-model="tmpDeskType">
            <el-radio :label="1">按桌台图样式</el-radio>
            <el-radio :label="2">按桌台状态 等,起,催,齐显示</el-radio>
        </el-radio-group>
    </div>
    <div class="desk content clearfix" style="width: 100%;height: 20%;overflow: auto;">
        <div class="desk-box opening" v-for="(item,index) in posDeskList" :key="item" @click="RevokeDeskChangeClick(item,index)">
            <div class="top" v-if="item.OpenDeskInfo" >
                <span>{{item.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
            </div>
            <p class="title">{{item.Desk_Name}}</p>
            <div class="bottom">
                <span v-if="item.OpenDeskInfo" >{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_SaleMoney}}</span> <!--点餐总金额-->
            </div>
        </div>
    </div>
    <div class="Popup-Bsic-bottom">
        <button class="Popup-Basic-left-bottom" @click="CloseClicks()" >取消</button>
        <button class="Popup-Basic-right-bottom" @click="SetDeskClick()">确定</button>
    </div>
    <analog-key-board ref="keyBoard" ></analog-key-board>
</modal>
</template>

<script>
import { differenceTime } from '/src/common';

/**全局初始化数据 */
let _data={
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
};

export default {
    name: 'servingDeskModel',
    props:{
        isShow:Boolean,
        PosRooms:Object,
        //选择桌号显示样式
        deskType:{
            type:Number,
            default:1
        }
    },
    data() {
        let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
        }
        return {
             /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            filternav:-1,
            DeskAutoSelce:'', //查询条件栏
            posDeskList:[], //
            TempList:[], //临时数组
            DeskStatus:1, //桌台状态，0 未开台 1 已开台
            MapConsumeMoney:false,
            //选择桌号显示样式  1按桌台图样式  2按桌台状态 等,起,催,齐显示
            tmpDeskType:this.deskType,
        }
    },
    computed:{
        /**所有桌台 */
        allRooms(){
            let data=[];
            this.PosRooms?.forEach(item=>{
                item.PosDesks?.forEach(pos=>{
                    data.push(pos)
                })
            })
            return data;
        },
        /**当前显示 桌台 */
        showPosDeskList(){
            let data=this.allRooms;
            if(this.filternav>=0){
                data=this.PosRooms[this.filternav].PosDesks;
            }
            return data;
        },
        /** 筛选 桌台 */
        deskFilter(){
            let data=this.showPosDeskList||[];
            if(this.DeskAutoSelce!=''){
                let searchTxt=this.DeskAutoSelce.toLowerCase();
                data= data.filter((item)=>{
                    return (item.Desk_Code||"").toLowerCase().indexOf(searchTxt)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(searchTxt)>=0;
                });
            }
            return data;
        },
        getDeskList(){
            //排序已选中的 桌台
            return this.deskFilter?.filter(it=> !this.posDeskList?.some(desk=>it.Desk_AutoID==desk.Desk_AutoID) );
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.EmptyDatas();
        })
    },
    methods:{
         //每次打开弹窗都是一次新换台，需要清理之前的数据
        EmptyDatas(){
            if(this.isShow){
                this.tmpDeskType=this.deskType;
                this.TempList=[];
                this.posDeskList=[];
                this.DeskAutoSelce=''
                this.NavigationClick(-1);
                let _RoomsList=this.$cacheData.servingRoomsList.getRoomsList();

                if(_RoomsList?.length>0){
                    this.getDeskList.forEach(item=>{
                        if( _RoomsList.indexOf(item.Desk_AutoID)>=0){
                            this.DeskMergeClick(item);
                        }
                    })
                }
                this.MapConsumeMoney=_data.RunCloudPosHideDeskMapConsumeMoney
            }
        },
        //餐厅名称导航条切换
        NavigationClick(index) {
            //清空查询条件栏
            if(this.DeskAutoSelce.length>0){
                this.DeskAutoSelce='';
            }
            this.filternav = index;
        },
        //桌号检索
        DeskTextClick() {
            this.filternav=-1;
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            return differenceTime(beginTime);
        },  
       
        //关闭弹出框
        CloseClicks(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel");
        },
        //提交并台数据
        SetDeskClick(){
            this.$emit("servingDeskRetrun",this.posDeskList,this.tmpDeskType);
        },
        //获取点击的桌台数据
        DeskMergeClick(desk){
            if(!this.posDeskList.some(arr => arr.Desk_AutoID == desk.Desk_AutoID)){
                this.posDeskList.push(desk);
            }
        },
        //撤销选择的数据
        RevokeDeskChangeClick(desk,index){
            if(desk && index>=0){
                this.posDeskList.splice(index,1);
            }
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    }
}

</script>

<style lang="scss">
@import "./servingDeskModel.scss";
</style>