<template>
    <modal class="numModifyModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">数量确认
            <span class="close" @click="hide()">×</span>
        </div>
        <div class="modal-body" ref="num">
            <div>
                <div class="payamount">数量:
                    <input-pattern ref="allNum" data-model='allNum' v-model="allNum" @keyup.enter="inputCursor()"  @focus="(e)=>e.target.select()"  pattern="allNum" />
                </div>
            </div>
            <div class="keyboard">
                <div class="right-key">
                    <table>
                        <tr>
                            <td><span data-value="10">10</span></td>
                            <td><span data-value="7">7</span></td>
                            <td><span data-value="8">8</span></td>
                            <td><span data-value="9">9</span></td>
                            <td><span data-value="clean">C</span></td>
                        </tr>
                        <tr>
                            <td><span data-value="20">20</span></td>
                            <td><span data-value="4">4</span></td>
                            <td><span data-value="5">5</span></td>
                            <td><span data-value="6">6</span></td>
                            <td><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span></td>
                        </tr>
                        <tr>
                            <td><span data-value="50">50</span></td>
                            <td><span data-value="1">1</span></td>
                            <td><span data-value="2">2</span></td>
                            <td><span data-value="3">3</span></td>
                            <td rowspan='2'><button class="confirm-btn" @click="confirm()">确认</button></td>
                        </tr>
                        <tr>
                            <td><span data-value="100">100</span></td>
                            <td><span class="disable" data-value=".">.</span></td>
                            <td><span data-value="0">0</span></td>
                            <td><span data-value="00">00</span></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </modal>
</template>
  
<script>
import {initKeyBoard} from '/src/common' 

/**会员支付 */
export default {
    name:"numModifyModel",
    props:{
        isShow:Boolean
    },
    data(){
        return {
            allNum:1,
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$refs.num,this.$refs.allNum.$el);
            //this.number=this.disRadioOrder?.Eat_Number;
            this.EmptyDatas()
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            this.EmptyDatas();
        },
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.allNum.focus();
            },100);
        },
        inputCursor(){
            this.confirm()
        },
        hide(){
            this.$emit("numCloseModel")
        },
        /**确定和回车事件 */
        confirm(){
            if((Number(this.allNum)+'').indexOf('.')!=-1){
                this.$message.warning('券数量不支持小数格式');
                return
            }
            if(Number(this.allNum)>0){
                this.$emit('cashConfirm',this.allNum)
            }else{
                this.$message.warning("输入的数量必须大于0!");
                return
            }
        },
    }
}
</script>

<style lang="sass">
@import "./numModifyModel.scss";
</style>