<template>
    <div class="servingOrder">
        <div class="topclass">
            <div class="diningTableDiv" :style="styleColor">
                <div class="roomClass" v-scroll-anime="{up:'pay-up',down:'pay-down',liClass:'.opening',fx:'x'}">
                    <template v-if="deskType==1">
                        <div class="opening" :class="{'empty': item.Desk_Status == 0,'preKnot':item.Desk_Status==3,selected:posDeskIndex==item.Desk_AutoID}" 
                            v-for="(item,index) in SelectRoomsList" :key="index" @click="RoomClick(item)">
                            <div class="top" v-if="item.OpenDeskInfo" >
                                <span>{{item.OpenDeskInfo.Person_Num}}人</span>
                                <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span> 
                            </div>
                            <p class="title">{{item.Desk_Name}}</p>
                            <div class="bottom">
                            <span v-if="item.OpenDeskInfo">{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> 
                            </div>
                            <i class="iconfont icon-gou1"></i>
                        </div> 
                    </template>
                    <template v-else>
                        <flicker-ani class="item-li" :class="{selected:posDeskIndex==item.Desk_AutoID}"
                            :ainClass="(['empty','opening','reserve','waitcall','rise','urge','qi'])[item.KitDesk_Status]"
                            :style="deskStatusStyle(item)"
                            @click="RoomClick(item)"
                            v-for="item in PosDesksStatusPage" :key="item">
                            <div class="name" :class="{textLeft:item.KitDesk_Status==4 || item.KitDesk_Status==5}">
                                <span>{{(['空台','开台','预订','等叫','起菜','催菜','上齐'][item.KitDesk_Status])}}</span>
                                <div class="top-right" v-if="item.KitDesk_Status==4 || item.KitDesk_Status==5">
                                    <template v-if="item.Eat_CountDown<60">
                                        <span class="time">{{item.Eat_CountDown}}</span><span class='unit'>分</span>
                                    </template>
                                    <template v-else-if="item.Eat_CountDown>=60">
                                        <span class="time">{{parseInt(item.Eat_CountDown/60)}}</span><span class='unit'>时</span>
                                    </template>
                                </div>
                            </div>
                            <div class="title">
                                <span class="nowrap">{{item.Desk_Name}}</span>
                            </div>
                            <i class="iconfont icon-gou1"></i>
                        </flicker-ani>
                    </template>
                </div>
                <div class="float-rightbnt">
                    <div class="bnt-fangxiang" ref="pay-up"><i class="iconfont icon-fangxiang-left"></i></div>
                    <div class="bnt-fangxiang" ref="pay-down"><i class="iconfont icon-fangxiang-right"></i></div>
                </div>
            </div>
        </div>
        <div class="panel-table">
            <div class="panel-heading">
                <div class="tr">
                    <div class="td" style="text-align: left;padding-left: 13px;">上菜级别</div>
                    <div class="td">菜品名称</div>
                    <div class="td">数量</div>
                    <div class="td">状态</div>
                    <div class="td">下单&起菜</div>
                    <div class="td">标准时长(分)</div>
                    <div class="td" style="text-align: left;">理论完成时间</div>
                    <div class="td">划菜</div>
                    <div class="td">上桌</div>
                    <div class="td">出单位置</div>
                </div>
            </div>
            <div class="panel-body">
                <div class="slide-wrapper" :class="{color:index%2==0,selectedRow:foodIndex==index}" v-for="(item,index) in orderMenuList.ServingOrderConsumeList"  :key="index" @click="OrderClick(item,index)">
                    <div class="line"></div>
                    <div class="tr">
                        <div class="td" style="width: 60px;margin:5px 0 0 0;">
                            <div class="Level" >{{item.FoodLevel}}</div>
                        </div>
                        <div class="td name" :style="orderColor(item.Speed_Status,item.IsOver,1)" style="color:#000000;">
                            <span class="span">{{item.Food_Name}}</span>
                        </div>
                        <div class="td" :title="item.Food_Size" >{{item.Food_Number}}
                            <p class="Size" style="font-size:11px;">¥{{item.Eat_SaleMoney}}/{{item.Food_Size.substring(0,1)}}</p>
                        </div>
                        <div class="td">
                            <div class="LevelStatus" :style="orderColor(item.Speed_Status,item.IsOver,2)" >
                                <p class="Size" :style="sizeColor(item.Speed_Status,item.IsOver)">{{item.Food_StatusSurfaceDesc}}</p>
                            </div>
                        </div>
                        <div class="td">{{new Date(item.Eat_Time).Format("hh:mm")}}<p class="Size">{{item.Eat_Czy}}</p></div>
                        <div class="td">{{item.CookMin}}</div>
                        <div class="td cook">
                            <p class="EndTime">{{new Date(item.EndCookTime).Format("hh:mm")}}</p>
                            <div class="EndCook" :style="orderColor(5,'',1)" v-show="item?.TimeFlag=='超' && item.Eat_CountDown>0">超{{item.Eat_CountDown}}分</div>
                            <div class="EndCook" :style="orderColor(7,'',1)" v-show="item?.TimeFlag=='临'">临{{item.Eat_CountDown}}分</div>
                        </div>
                        <div class="td" v-if="item.Hc_Czy">{{new Date(item.Hc_Time).Format("hh:mm")}}<p class="Size">{{item.Hc_Czy}}</p></div>
                        <div class="td" v-else></div>
                        <div class="td" v-if="item.Ud_Czy">{{new Date(item.Ud_Time).Format("hh:mm")}}<p class="Size">{{item.Ud_Czy}}</p></div>
                        <div class="td" v-else></div>
                        <div class="td" style="line-height: 50px;">{{item.KitPlace_Name}}</div>
                        <p class="mas" v-if="item.Eat_MasName && item.Eat_MasName!='null'"><i class="iconfont icon-yudiancan"></i>{{item.Eat_MasName}}</p>
                        <p class="notice" :class="{NoMas:!item.Eat_MasName}" v-if="item.NoticeDesc!='' && item.NoticeDesc!=null"><i class="iconfont icon-yudiancan"></i>{{item.NoticeDesc}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomclass">
            <div class="btmicon" @click="DeskClick()"><i class="icon iconfont icon-a-leimupinleifenleileibie"></i></div>
            <div class="btmrigt">
                <div class="mrigtleft">
                    <p class="total">合计:</p><p class="CNY">¥</p><p class="totalMoney">{{orderMenuList.TotalMoney>0?orderMenuList.TotalMoney:0}}</p>
                    <p class="numReach Number">共{{orderMenuList.TotalEat_Num>0?orderMenuList.TotalEat_Num:0}}道{{orderMenuList.TotalEat_Number>0?orderMenuList.TotalEat_Number:0}}份</p>
                    <p class="numReach" v-show="ProfitRate==true">达成率:</p>
                    <p class="Reach" v-show="ProfitRate==true">{{orderMenuList.CompleteRate>0?orderMenuList.CompleteRate:0}}%</p>
                </div>
                <div class="mrigtrigt">
                    <div class="btnInvoicing" @click="theInvoicing()">结账</div>
                    <div @click="addfood()">加菜</div>
                    <div @click="servingSubClick()">打印</div>
                    <div @click="urgeFoodContent()">催/起菜</div>
                    <div @click="theRefresh()">刷新</div>
                    <div class="btmOne" @click="hide()" >返回</div>
                </div>
            </div>
        </div>
        <!--桌台选择-->
        <modal-load :isShow="deskShow">
            <serving-desk-model :isShow="deskShow" :PosRooms="PosRooms" :deskType="deskType" v-on:closeModel="deskShow=false,this.$keyavail=false" @servingDeskRetrun="servingDeskRetrun"></serving-desk-model>
        </modal-load>
        <!--穿透-->
        <modal-load :isShow="penetrateShow" :isUnload="true">
            <serving-penetrate-model :isShow="penetrateShow" :KdsFoodColors="KdsFoodColors" :deskName="posDesk.Desk_Name" :selectFood="selectFood" v-on:closeModel="penetrateShow=false,this.$keyavail=false" @confirmDeskComplete="confirmDeskComplete"></serving-penetrate-model>
        </modal-load>
        <!-- 催菜 -->
        <modal-load :isShow="urgeFoodShow">
            <urge-food-model :isShow="urgeFoodShow" v-on:closeModel="urgeFoodShow=false,this.$keyavail=false" 
                :orderInfo="orderInfo" 
                :orderMenuList="TemporderMenuList" 
                :allfoodSubCategorys="allfoodSubCategorys" 
                @urgeFoodReturn="urgeFoodReturn">
            </urge-food-model>
        </modal-load>
        <!--弹窗-->
        <modal-load :isShow="subItemShow">
            <serving-SubItem-model :isShow="subItemShow" v-on:closeModel="subItemShow=false,this.$keyavail=false" @GetPrintContent="GetPrintContent" ></serving-SubItem-model>
        </modal-load>
    </div>
</template>
<script>

import {newGuid,differenceTime} from '../../../../../src/common' 
import servingDeskModel from '../servingOrder/servingDeskModel/servingDeskModel.vue'
import servingPenetrateModel from '../servingOrder/servingPenetrateModel/servingPenetrateModel.vue'
import urgeFoodModel from '../diningDetail/urgeFoodModel/urgeFoodModel.vue'
import servingSubItemModel from './servingSubItemModel/servingSubItemModel.vue'

/**全局初始化数据 */
let _data={
    /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
    orderMap_Type:1,
    arg:{row:6,col:6,marginW:'6',marginH:'6'},
    args:{row:3,col:6,marginW:'8',marginH:'8'},
    KdsSettings:'',/**菜品速度状态对应颜色 */
    CheckOutChangeNumFood:0, /**结账时，如存在未改量菜品处理方式，0:’允许结账’,1:’仅提醒需改量菜品，允许结账’,2:’存在未改量菜品，不允许结账 */
    RunOrderDeviceShowBillProfitRate:false, /**启用点单设备显示账单毛利率，true: 显示，false:不显示 */
    LockScreenSeconds:0, //启用触摸屏N秒后无操作自动锁屏(不填或0,则不启用)
};

const timeUserFun=(()=>{
	let t = _data.LockScreenSeconds;    // 默认参数
	if(t==0){
		t=86400; /**一天 */
	}
	console.log('进来的时间t:'+t)
	let userTime = t*1000;
	let timeFun=()=>{}
	let objTime = {
		init:0,
		time:function(){
			if(typeof(timeFun)=='function'){timeFun();}
		},
		eventFun:function(){
			clearTimeout(testUser);
			testUser = setTimeout(objTime.time,userTime);
		}
	}
	var testUser = setTimeout(objTime.time,userTime);
	var body = document.querySelector('html');
	body.addEventListener("click",objTime.eventFun);
	body.addEventListener("keydown",objTime.eventFun);
	body.addEventListener("mousemove",objTime.eventFun);
	body.addEventListener("mousewheel",objTime.eventFun);
	body.addEventListener("touchstart",objTime.eventFun);
	body.addEventListener("touchmove",objTime.eventFun);
	body.addEventListener("touchend",objTime.eventFun);
	return (time,fun)=>{
		if(time>0){
			userTime = time*1000;
			//userTime = time*1000*60;
		}
		timeFun=fun;
		objTime.eventFun();
	}
})();


export default {
    name:"servingOrder",
    components:{
        servingDeskModel,
        servingPenetrateModel,
        urgeFoodModel,
        servingSubItemModel
    },
    data() {
        let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.CheckOutChangeNumFood = globalSysSetting.CheckOutModelWithChangeNumFood;
            _data.RunOrderDeviceShowBillProfitRate =globalSysSetting.RunOrderDeviceShowBillProfitRate;
        }
        return {
            deskShow:false, /**桌台 */
            penetrateShow:false, /**穿透 */
            urgeFoodShow:false, /**催菜 */
            subItemShow:false,/**打印 */
            /**桌台原数据 */
            DeskMap:null,
            RoomsList:[],
            posDeskIndex:'',
            posDesk:'',
            orderMenuList:'', /**订单信息 */
            selectFood:'', /**选中的菜品数据 */
            foodIndex:'',
            KdsFoodColors:'', /**菜品状态 */
            KdsSettings:null,
            foodColorBtn:[{name:'等叫',id:1,color:''},{name:'超时',id:5,color:''},{name:'划菜',id:23,color:''},{name:'上桌',id:24,color:''},{name:'临界',id:7,color:''},{name:'即起',id:2,color:''}],
            btnStyle:'',
            /**大类数据 */
            foodMainCategory:[],
            /**全部小类数据 */
            allfoodSubCategorys:[],
            //菜品数据 
            posFoodData:{},
            /**订单数据 */
            orderInfo:[],
            TemporderMenuList:'',
            ProfitRate:false,//启用点单设备显示账单毛利率，true: 显示，false:不显示
            /**桌台图 */
            servingRoomDeskList:'',
            /*是否被卸载 */
            isUnmounted:false,
            /**KDS的消息 */
            kdsData:{},
            /**保存订单里菜品上一步的状态 */
            orderFoodState:Object,
            //选择桌号显示样式  1按桌台图样式  2按桌台状态 等,起,催,齐显示
            deskType:1,
        }
    },
    computed:{
        /**桌台数据 */
        PosRooms(){
            return this.DeskMap?.data?.PosRooms||[];
        },
        /**菜品数据 */
        getPosFoodData(){
            return this.posFoodData.data||[];
        },
        //选中的桌台数据
        SelectRoomsList(){
            let ids=this.RoomsList||[];
            let data=[];
            if(!this.PosRooms){
                this.$global.PosRooms?.forEach((it)=>{
                    it.PosDesks?.forEach(desk=>{
                        if(ids.indexOf(desk.Desk_AutoID)>=0){
                            data.push(desk);
                        }
                    })
                })
            }else{
                this.PosRooms?.forEach((it)=>{
                    it.PosDesks?.forEach(desk=>{
                        if(ids.indexOf(desk.Desk_AutoID)>=0){
                            data.push(desk);
                        }
                    })
                })
            }
            
            return data;
        },
        /**计算 桌台 起菜状态颜色 */
        PosDesksStatusPage(){
            let data=this.SelectRoomsList||[];
            if(data.length>0){//按桌台状态 显示
                let dayTime=(this.$global.currentTime||new Date()).getTime();//当前时间
                data.forEach(it=>{
                    if(it.KitDesk_Status==4 || it.KitDesk_Status==5){//起菜
                        let oldTime=new Date(it.Operate_Time).getTime();
                        if(dayTime-oldTime>0){
                            let diffTime=Math.floor((dayTime-oldTime)/(1000*60));//超时 多少分钟
                            it.Eat_CountDown=diffTime;
                        }else{
                            it.Eat_CountDown=0;
                        }
                    }
                })
            }
            return data;
        },
        //起菜后超过多长的时间的自定义阶梯颜色
        callUpTimeStepColors(){
            return this.KdsSettings?.KitUpTimeManageSet?.CallUpTimeStepColors;//数据默认是倒叙
        },
        //桌台状态颜色
        styleColor(){
            let styleColor={};
            //桌台状态颜色
            this.KdsSettings?.KdsFoodColors?.forEach((it)=>{
                if(it.Speed_Status==24){
                    // 上齐
                    styleColor["--qiColor"]=it.Color_Value;
                }else if(it.Speed_Status==3){
                    //催菜
                    styleColor["--urgeColor"]=it.Color_Value;
                }else if(it.Speed_Status==1){
                    //等叫
                    styleColor["--waitcallColor"]=it.Color_Value;
                }else if(it.Speed_Status==2){
                    //起菜
                    styleColor["--riseColor"]=it.Color_Value;
                }else if(it.Speed_Status==8){
                    //预订
                    styleColor["--reserveColor"]=it.Color_Value;
                }
            });
            return styleColor;
        }
    },
    created(){
       document.addEventListener("keydown",this.keydown)
    },
    /**当前位置的组件将要离开时触发 */
    beforeRouteLeave(to, from, next) {
        this.$global.servingUrlType=0
        next()
    },
    mounted(){
        this.posDesk='';
        this.$global.servingUrlType=1;
        this.ProfitRate=_data.RunOrderDeviceShowBillProfitRate;

        this.deskType=this.$cacheData.get("servingDeskType")||1;

        this.$cacheData.DeskActiveMap(false).then(d=>{
            this.DeskMap=d;
            this.RoomsList=this.$cacheData.servingRoomsList.getRoomsList();
            this.posDesk = this.$global.servingTheFood
            if(this.posDesk){
                this.posDeskIndex=this.posDesk.Desk_AutoID;
                this.RoomClick(this.posDesk)
            }
        }).catch((e)=>{})
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
           this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        //菜品状态颜色结构
        this.$cacheData.KdsSettings().then((d)=>{
            this.KdsSettings=d;
            this.KdsFoodColors=d.KdsFoodColors;
            console.log('KdsFoodColors:',d.KdsFoodColors)
            this.foodColorBtn.map(food=>{
                this.KdsFoodColors.map(kds=>{
                    if(food.id==kds.Speed_Status){
                        food.color='background:'+kds.Color_Value+''
                    }
                })
            })
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品状态数据', "提示", {confirmButtonText: "确定"});
        })
        //监听菜品的最新信息
        this.$mqTTClient.mqCloudPosData(()=>{
            return new Promise((resolve) => {
                resolve({});
            });
        },"ServingOrder_Data",(d,newData)=>{
            if(newData.Kit_PostID==8033){
                if(this.$route.name=="servingOrder"){
                    if(newData?.KdsFoodItems && newData.Kit_PostID=='8033'){
                        //speedStatus:1：等叫；2：即起；5：超时；7：临界点；23：已划菜未上桌 ；24：已上桌。
                        this.newDataChange(newData,23,'已划菜')
                    }
                }
            }
            if(newData.Kit_PostID==8034){
                if(this.$route.name=="servingOrder"){
                    if(newData?.KdsFoodItems && newData.Kit_PostID=='8034'){
                        //speedStatus:1：等叫；2：即起；5：超时；7：临界点；23：已划菜未上桌 ；24：已上桌。
                        this.newDataChange(newData,24,'已上桌')
                    }
                }
            }
        })
    },
    watch:{
        //缓存选中数据
        RoomsList:{
            handler(){
                this.$cacheData.servingRoomsList.set(this.RoomsList);
            },
            deep:true
        },
        deskType(){
            //上菜顺序 桌号显示样式
            this.$cacheData.set("servingDeskType",this.deskType);
        },
        getPosFoodData(){
            this.foodMainCategory=this.getPosFoodData;
            let all=[];//全部大类
            this.foodMainCategory.forEach(category=> {
                if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                    all=all.concat(category.FoodSubCategorys);
                }
            });
            all._all=[];//全部小类
            if(all.length>0){
                all.forEach(sub => {
                    let _SubCategory={SubCategory_Name:sub.SubCategory_Name,SubCategory_ID:sub.SubCategory_ID}
                    this.allfoodSubCategorys.push(_SubCategory);
                    if(sub.PosFoods && sub.PosFoods.length>0)
                    all._all=all._all.concat(sub.PosFoods)
                });
            }
        },
        SelectRoomsList:{
            handler(newVal,oldVal){
                this.SelectRoomsList.forEach(room=>{
                    if(room.Desk_Status==0 && room.Desk_AutoID==this.posDesk.Desk_AutoID){
                        this.posDeskIndex=''
                        this.posDesk=''
                        this.orderMenuList=''
                    }
                })
            },
            immediate:true,
            deep:true
        },
    },
    methods:{
        /** 起菜后超过多长的时间的自定义阶梯颜色 */
        deskStatusStyle(item){
            let stepColor= this.callUpTimeStepColors?.find(it=>it.CallUpOverTime<=item.Eat_CountDown);
            if(stepColor){
                return {'--riseColor':stepColor.ColorValue};//状态颜色
            }
            return {};
        },
        hide(){
            this.$router.push({
                path: 'diningTable',name:'diningTable'
            });
        },
        newDataChange(newData,speedStatus,faceDesc){
            /**foodStatus:3划菜,4上桌 */
            newData?.KdsFoodItems.forEach(kds=>{
                if(this.orderMenuList?.ServingOrderConsumeList && kds?.Kit_PosterName){
                    this.orderMenuList?.ServingOrderConsumeList.forEach(ser=>{
                        //KDS传入的订单ID和选择的订单ID相同,并且KDS传入的菜品消费明细ID和该订单里面的菜品消费明细ID相同,则调整菜品的状态
                        if(kds.Eat_AutoID==this.orderMenuList.Eat_AutoID && kds.Eat_ConsumeID==ser.Eat_ConsumeID){
                            if(speedStatus==23 && ser.Speed_Status!=24){
                                ser.Hc_Time=kds.Kit_PostTime
                                ser.Hc_Czy=kds.Kit_PosterName
                                ser.IsOver=0
                                ser.Food_StatusSurfaceDesc=faceDesc
                                ser.Food_StatusInsideDesc=faceDesc
                                ser.Speed_Status=speedStatus
                            }
                            if(speedStatus==24){
                                if(ser?.Ud_Czy){
                                    ser.Ud_Czy=kds.Kit_PosterName
                                }else{
                                    ser = Object.assign(ser,{Ud_Czy:kds.Kit_PosterName})
                                }
                                ser.Ud_Time=kds.Kit_PostTime
                                ser.IsOver=1
                                ser.Food_StatusSurfaceDesc=faceDesc
                                ser.Food_StatusInsideDesc=faceDesc
                                ser.Speed_Status=speedStatus
                            }
                        }
                    })
                }
                //取消操作
                if(this.orderMenuList?.ServingOrderConsumeList && kds?.Kit_PosterName==''){
                    this.orderMenuList?.ServingOrderConsumeList.forEach(ser=>{
                        //KDS传入的订单ID和选择的订单ID相同,并且KDS传入的菜品消费明细ID和该订单里面的菜品消费明细ID相同,则调整菜品的状态
                        if(kds.Eat_AutoID==this.orderMenuList.Eat_AutoID && kds.Eat_ConsumeID==ser.Eat_ConsumeID){
                            //已上桌的情况
                            if(ser.Speed_Status==24){
                                //如果划菜不为空,则变成划菜状态
                                if(ser?.Hc_Time && ser?.Hc_Czy){
                                    ser.Speed_Status=23
                                    ser.IsOver=0
                                    ser.Ud_Time=''
                                    ser.Ud_Czy=''
                                    ser.Food_StatusSurfaceDesc='已划菜'
                                    ser.Food_StatusInsideDesc='已划菜'
                                }else{
                                    //菜品其他:等叫,即,超,临,催菜状态调整
                                    this.sppedfaceDesc(ser)
                                }
                            //如果是已划菜
                            }else if(ser.Speed_Status==23){
                                //如果上桌不为空,则变成上桌状态
                                if(ser?.Ud_Time && ser?.Ud_Czy){
                                    ser.Speed_Status=24
                                    ser.IsOver=1
                                    ser.Hc_Time=''
                                    ser.Hc_Czy=''
                                    ser.Food_StatusSurfaceDesc='已上桌'
                                    ser.Food_StatusInsideDesc='已上桌'
                                }else{
                                    //菜品其他:等叫,即,超,临,催菜状态调整
                                    this.sppedfaceDesc(ser)
                                }
                            }
                        }
                    })
                }
            })
        },
        //菜品状态调整
        sppedfaceDesc(ser){
            //如果是等叫
            if(ser.Food_Speed=='等叫'){
                ser.Speed_Status=1
            }
            if(ser.Food_Speed=='即'){
                ser.Speed_Status=2
                if(ser?.TimeFlag=='超'){
                    ser.Speed_Status=5
                }
                if(ser?.TimeFlag=='临'){
                    ser.Speed_Status=7
                }
            }
            if(ser.Food_Speed=='催菜'){
                ser.Speed_Status=3
            }
            ser.Food_StatusSurfaceDesc=ser.Food_Speed
            ser.Food_StatusInsideDesc=ser.Food_Speed
            ser.Hc_Time=''
            ser.Hc_Czy=''
            ser.IsOver=0
        },
        //催菜
        urgeFoodContent(){
            if(this.posDeskIndex){
                this.urgeFoodShow = true;
            }else{
                this.$message.warning('请先选择桌台');
            }
        },
        //催菜返回
        urgeFoodReturn(data,Order_ConsumeList){
            this.urgeFoodShow = false;
            this.posDesk = this.$global.servingTheFood
            if(this.posDesk){
                this.posDeskIndex=this.posDesk.Desk_AutoID;
                this.theRefresh(this.posDesk)
            }
        },
        //刷新
        theRefresh(){
            if(this.posDesk){
                this.RoomClick(this.posDesk)
            }
        },
        //加菜
        addfood(){
            if(this.posDeskIndex){
                this.$router.push({
                    path: 'diningDetail',name:'diningDetail',
                    params:{
                        PosDesks:JSON.stringify(this.posDesk),
                        Desk_AutoID:this.posDesk.Desk_AutoID,
                        Eat_AutoID:this.posDesk.OpenDeskInfo.Eat_AutoID,
                        Desk_Locked:this.posDesk.Is_Desk_Locked,
                        urlType:1
                    }
                });
            }else{
                this.$message.warning('请先选择桌台');
            }
        },
        servingSubClick(){
            this.subItemShow=true
        },
        //打印消费清单
        GetPrintContent(item){
            this.subItemShow=false
            if(!this.posDeskIndex){
                this.$message.warning('请先选择桌台');
                return
            }
            if(this.isPlace==false){
                this.$message.error('没有需要打印的订单');
                return
            }
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                    Body_ID:newGuid(),
                    UserID:userInfo?.User_ID,
                    PrintBusiness:1,  //1.触摸屏 this.orderMenuList.Order_Source
                    PrintType:item.PrintType,    //1.消费清单2.结账清单8.客看单
                    Eat_CheckIndex:this.orderMenuList.Eat_CheckIndex, //结账单号
                    Order_ID:this.orderMenuList.Eat_AutoID,//订单id
                    Operator_Name:userInfo?.Login_Name,
                    Is_UnionPrint:false,  //是否联台打印
                    Is_CheckedOut:false,
            }
            if(_data.CheckOutChangeNumFood!=0){
                let _FoodName= this.orderFoodName()
                if(_FoodName!=''){
                    let title=''
                    if(item.PrintType==1){
                        title = '订单中:'+_FoodName+',菜品暂未改量,是否继续打印消费清单?'
                    }
                    if(item.PrintType==8){
                        title = '订单中:'+_FoodName+',菜品暂未改量,是否继续打印客看单?'
                    }
                    this.$confirm(title, '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                        this.GetPrintContentApi(param)
                    }).catch(() =>{});
                }else{
                    this.GetPrintContentApi(param)
                }
            }else{
                this.GetPrintContentApi(param)
            }
        },
        /**打印接口 */
        GetPrintContentApi(param){
            const loading = this.$loading({
                text: "获取信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrintContent",param).then((data)=>{
                loading.close();
                
                if(param.PrintType==1){
                    _data.NoNeedPrintBill=false
                }
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.theRefresh()
                }else{
                    setTimeout(()=>{
                        this.$webBrowser.posPrint(data.ResponseBody);
                    },100);
                    this.theRefresh()
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**未改量菜品名称返回 */
        orderFoodName(){
            let _FoodName=''
            this.orderMenuList.ServingOrderConsumeList.map(order=>{
                if(order.ChangeNum_Status==1 && order.Food_Number>0 && order.Food_Type!=2){
                    _FoodName+=order.Food_Name+','
                }
            })
             return _FoodName = _FoodName.substring(0,_FoodName.lastIndexOf(','))
        },
        //结账
        theInvoicing(){
            if(this.posDeskIndex){
                this.$router.push({
                    path: 'diningDetail',name:'diningDetail',
                    params:{
                        PosDesks:JSON.stringify(this.posDesk),
                        Desk_AutoID:this.posDesk.Desk_AutoID,
                        Eat_AutoID:this.posDesk.OpenDeskInfo.Eat_AutoID,
                        Desk_Locked:this.posDesk.Is_Desk_Locked,
                        urlType:2
                    }
                });
            }else{
                this.$message.warning('请先选择桌台');
            }
        },
        DeskClick(){
            this.deskShow = true
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            return differenceTime(beginTime);
        },  
        servingDeskRetrun(posDeskList,deskType){
            this.deskType=deskType;//显示类型
            this.deskShow = false
            if(posDeskList){
                this.RoomsList = posDeskList.map(it=>it.Desk_AutoID);
                let _type=0
                this.SelectRoomsList.forEach(room=>{
                    if(room.Desk_AutoID==this.posDesk.Desk_AutoID){
                        _type=1
                    }
                })
                if(_type==0){
                    this.posDeskIndex=''
                    this.posDesk=''
                    this.orderMenuList=''
                }
            }
        },
        OrderClick(order,index){
            this.selectFood = order
            console.log('selectFood:',this.selectFood)
            this.foodIndex=index
            this.penetrateShow=true
        },
        orderColor(level,IsOver,type){
            let _color=''
            this.KdsFoodColors.map(kds=>{
                if(level==kds.Speed_Status){
                    _color='background:'+kds.Color_Value+';'
                }
                // if(level==23 || level==24 || IsOver==1){
                //     _color='color:#000000;background:'+kds.Color_Value+'';
                // }
            })
            if(type==2 && level!=23 && level!=24){
                _color=''
            }
            return _color
        },
        sizeColor(level,IsOver){
            let _color=''
            this.KdsFoodColors.map(kds=>{
                if(level==kds.Speed_Status){
                     _color='color:#000000'
                }
                if(level==23 || level==24 || IsOver==1){
                    _color='color:#000000'
                }
            })
            return _color
        },
        RoomClick(posDesk){
            this.posDeskIndex=''
            this.posDesk=posDesk
            this.$global.servingTheFood = this.posDesk
            if(posDesk?.OpenDeskInfo){
                this.posDeskIndex=posDesk.Desk_AutoID;
                const loading = this.$loading({
                    text: '数据加载中',
                    spinner: "el-icon-loading",
                    background: "rgba(255, 255, 255, 0)",
                });
                this.orderMenuList=''
                let userInfo=this.$auth.getUserInfo()
                //获取上桌订单信息
                this.$httpAES.post('Bestech.CloudPos.GetServingOrderInfo',{
                    Body_ID:newGuid(),
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Eat_DeskID:posDesk.Desk_AutoID, //桌台ID
                }).then((data)=>{
                    loading.close();
                    console.log('data:',data)
                    if(data?.ResponseHeader.ResultCode==0){
                        this.orderMenuList = data.ResponseBody;
                        if(this.orderMenuList.ServingOrderConsumeList.length==0){
                            // this.$message.warning('没有获取到桌台对应的订单数据,请联系管理员进行查看');
                            // this.posDeskIndex=''
                            // this.posDesk=''
                            // this.orderMenuList=''
                            return
                        }
                        this.orderMenuList.ServingOrderConsumeList.map(ser=>{
                            let NoticeDesc=''
                            for (let i = 0; i < ser.NoticeDesc.length; i++){
                                if(ser.NoticeDesc[i] && ser.NoticeDesc[i] !='null'){
                                    NoticeDesc += ser.NoticeDesc[i]
                                }
                            }
                            if(NoticeDesc){
                                ser.NoticeDesc = NoticeDesc
                            }else{
                                ser.NoticeDesc=''
                            }
                        })
                        this.SelectRoomsList.map(room=>{
                            if(room.OpenDeskInfo){
                                if(Number(room.OpenDeskInfo.Eat_FoodCounts)!= Number(this.orderMenuList.TotalEat_Number) && room.OpenDeskInfo.Eat_AutoID==this.orderMenuList.Eat_AutoID){
                                    room.OpenDeskInfo.Eat_FoodCounts = Number(this.orderMenuList.TotalEat_Number)
                                    room.OpenDeskInfo.Eat_CheckMoney = Number(this.orderMenuList.TotalMoney)
                                    //this.PosRoomsUpdate(room)
                                }
                                if(room.OpenDeskInfo.Eat_AutoID==this.orderMenuList.Eat_AutoID && this.orderMenuList.Eat_CheckIndex){
                                    if(this.orderMenuList.Eat_CheckIndex){
                                        room.Desk_Status=3
                                    }
                                    //this.PosRoomsUpdate(room)
                                }
                            }
                        })
                        this.orderMenuList.ServingOrderConsumeList.sort(this.compare('FoodLevel',''));
                        //获取订单的信息
                        this.orderInfomethod()
                    }else{
                        if(data?.ResponseHeader.ResultCode==3007){
                            this.SelectRoomsList.forEach(room=>{
                                if(room.Desk_AutoID==this.posDesk.Desk_AutoID){
                                    room.Desk_Status=0
                                    this.posDeskIndex=''
                                    this.posDesk=''
                                    this.orderMenuList=''
                                }
                            })
                        }
                        this.SelectRoomsList.forEach(it=>{
                            if(it.Desk_Status==0){
                                it.OpenDeskInfo=null
                            }
                        })
                        this.$message.warning(data.ResponseHeader.ResultDesc);
                        
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                });
            }else{
                this.posDeskIndex=''
                this.posDesk=''
                this.orderMenuList=''
            }
        },
        /**获取订单的信息 */
        orderInfomethod(){
            let userInfo=this.$auth.getUserInfo()
            this.$httpAES.post('Bestech.CloudPos.GetOrderInfo',{
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Eat_DeskID:this.posDesk.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.posDesk?.OpenDeskInfo?.Eat_AutoID, //订单Id
                Is_UnionOrder:false,
                Is_LockDesk:false
                }).then((data)=>{
                    if(data?.ResponseHeader.ResultCode==0){
                        this.orderInfo = data.ResponseBody;
                        this.TemporderMenuList = data.ResponseBody.Order_ConsumeList;
                    }else{
                        this.$message.warning(data.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                });
                // setTimeout(() => {
                //     this.$nextTick(()=>{
                //         animation.scrollTopAnchor(this.$refs.box,".slide-wrapper.selectedRow")
                //     })
                // },100);
        },
        sortKey(list,key){
            return list.sort(function(a,b){
                var x=a[key];
                var y=b[key];
                return ((x<y)?-1:(x>y)?1:0)
            })
        },
        /**桌台数据更新 */
        PosRoomsUpdate(room){
            this.PosRooms.map(pos=>{
                pos.PosDesks.map(desk=>{
                    if(desk.Desk_AutoID==room.Desk_AutoID && desk.OpenDeskInfo.Eat_FoodCounts!=room.OpenDeskInfo.Eat_FoodCounts){
                        desk.OpenDeskInfo.Eat_FoodCounts = room.OpenDeskInfo.Eat_FoodCounts
                    }
                })
            })
            this.$global.servingPosRooms = this.PosRooms
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val1 - val2 || Times2 - Times1;
            }
        },
        //确认上桌返回
        confirmDeskComplete(){
            this.penetrateShow=false;
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.orderMenuList.ServingOrderConsumeList.forEach(ser=>{
                if(ser.Eat_ConsumeID==this.selectFood.Eat_ConsumeID){
                    ser = Object.assign(ser,{Ud_Czy:this.userInfo?.Login_Name})
                    ser.Food_StatusSurfaceDesc='已上桌'
                    ser.Food_StatusInsideDesc='已上桌'
                    ser.Speed_Status=24
                    ser.Ud_Time=new Date().Format("yyyy-MM-dd hh:mm:ss")
                    ser.IsOver=1
                    this.selectFood=ser
                }
            })
            //this.theRefresh()
        },
    },
}
</script>
<style lang="scss">
@import "./servingOrder.scss";
</style>